// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as Char from "../../../../libs/Char.res.js";
import * as Hooks from "../../../../libs/Hooks.res.js";
import * as Label from "../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Select from "../../../../styleguide/forms/Select/Select.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Debounce from "rescript-debounce/src/Debounce.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Textarea from "../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Uploader from "../../../../styleguide/forms/Uploader/Uploader.res.js";
import * as ReactForm from "../../../../libs/ReactForm.res.js";
import * as TextField from "../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DatePicker from "../../../../styleguide/forms/DatePicker/DatePicker.res.js";
import * as IconUpload from "../../../../styleguide/icons/IconUpload.res.js";
import * as Visibility from "../../../../libs/Visibility.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ProjectWizard from "../_components/ProjectWizard/ProjectWizard.res.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as ReactGeoSuggest from "../../../../bindings/ReactGeoSuggest.res.js";
import AddDays from "date-fns/addDays";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanel from "../_components/ProjectWizardPanel/ProjectWizardPanel.res.js";
import * as ReactNotifications from "react-notifications";
import * as ReactGeoSuggestField from "../../../../styleguide/forms/ReactGeoSuggestField/ReactGeoSuggestField.res.js";
import * as SignInModalContainer from "../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as ProjectWizardNavigation from "../_components/ProjectWizardNavigation/ProjectWizardNavigation.res.js";
import * as CreateNetworkProjectPrivateLineConfigurationForm from "./CreateNetworkProjectPrivateLineConfigurationForm.res.js";
import * as CreateNetworkProjectPrivateLineConfigurationScss from "./CreateNetworkProjectPrivateLineConfiguration.scss";
import * as CreateNetworkProjectPrivateLineConfiguration_DocumentsWithRfpDetails from "./CreateNetworkProjectPrivateLineConfiguration_DocumentsWithRfpDetails.res.js";

var css = CreateNetworkProjectPrivateLineConfigurationScss;

function domId(field, at) {
  return "network-project-private-line-configuration-" + (field + ("-" + String(at)));
}

function locationDomId(field, param) {
  return domId("location", param[0]) + (field + ("-" + String(param[1])));
}

function configurationTitle(at) {
  return "#" + (String(at + 1 | 0) + (Char.mdash + "Network Private Line Project"));
}

function locationTitle(at) {
  return "#" + (String(at + 1 | 0) + (Char.mdash + "Location (Z)"));
}

function add(x, status) {
  return Belt_Array.concat(x, [status]);
}

function remove(x, index) {
  return Belt_Array.keepWithIndex(x, (function (param, idx) {
                return index !== idx;
              }));
}

function toggle(x, index) {
  return Belt_Array.mapWithIndex(x, (function (idx, visibility) {
                if (index === idx) {
                  return Visibility.toggle(visibility);
                } else {
                  return visibility;
                }
              }));
}

function expandAll(x) {
  return Belt_Array.map(x, (function (param) {
                return "Shown";
              }));
}

var Visibilities = {
  add: add,
  remove: remove,
  toggle: toggle,
  expandAll: expandAll
};

function validate(form) {
  Belt_Array.forEachWithIndex(form.input.configurations, (function (ix, param) {
          form.blurConfigurationStartDate(ix);
        }));
}

function results(form) {
  return Belt_Array.mapWithIndex(form.input.configurations, (function (ix, param) {
                return form.configurationStartDateResult(ix);
              }));
}

function isValid(results) {
  return Belt_Array.every(results, (function (result) {
                if (result !== undefined && result.TAG === "Ok") {
                  return true;
                } else {
                  return false;
                }
              }));
}

var ValidateSaveAndContinueLaterForm = {
  validate: validate,
  results: results,
  isValid: isValid
};

var persistDraft = Debounce.make(900, (function (param) {
        if (param[1].TAG === "Draft") {
          return $$Promise.wait(Api.saveNetworkProjectPrivateLineConfigurationDraft(param[0]), (function (x) {
                        if (x.TAG === "Ok") {
                          "Draft saved!".success(undefined, 1000, ReactNotifications.NotificationManager);
                          return ;
                        }
                        SentryLogger.error1({
                              rootModule: "CreateNetworkProjectPrivateLineConfiguration",
                              subModulePath: {
                                hd: "Api",
                                tl: /* [] */0
                              },
                              value: "persistDraft",
                              fullPath: "CreateNetworkProjectPrivateLineConfiguration.Api.persistDraft"
                            }, "SaveNetworkProjectPrivateLineConfigurationDraft::Error", [
                              "Error",
                              x._0
                            ]);
                        "Something went wrong trying to save the draft".error(undefined, 1000, ReactNotifications.NotificationManager);
                      }));
        }
        
      }));

function submitForm(data, context, onFailure) {
  if (context.TAG === "Draft") {
    return $$Promise.wait(Api.saveNetworkProjectPrivateLineConfiguration(data), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.Network.Provider.newSelector("PrivateLine"));
                  }
                  SentryLogger.error1({
                        rootModule: "CreateNetworkProjectPrivateLineConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "submitForm",
                        fullPath: "CreateNetworkProjectPrivateLineConfiguration.Api.submitForm"
                      }, "SaveNetworkProjectPrivateLineConfiguration::Error", [
                        "Error",
                        x._0
                      ]);
                  onFailure();
                  "Something went wrong trying to save the draft".error(undefined, 1000, ReactNotifications.NotificationManager);
                }));
  }
  var projectId = context.projectId;
  $$Promise.wait(Api.updateNetworkProjectPrivateLineConfiguration(projectId, data), (function (x) {
          if (x.TAG === "Ok") {
            return Url.visit(Routes_Project.Network.Provider.editSelector(projectId, "PrivateLine"));
          }
          SentryLogger.error1({
                rootModule: "CreateNetworkProjectPrivateLineConfiguration",
                subModulePath: {
                  hd: "Api",
                  tl: /* [] */0
                },
                value: "submitForm",
                fullPath: "CreateNetworkProjectPrivateLineConfiguration.Api.submitForm"
              }, "UpdateNetworkProjectPrivateLineConfiguration::Error", [
                "Error",
                x._0
              ]);
          onFailure();
          "Something went wrong trying to update the project".error(undefined, 1000, ReactNotifications.NotificationManager);
        }));
}

function saveDraftProjectAndContinueLater(form, context) {
  if (context.TAG !== "Draft") {
    return $$Promise.wait(Api.updateNetworkPrivateLineDraftProjectAndContinueLater(context.projectId, form.input), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateNetworkProjectPrivateLineConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateNetworkProjectPrivateLineConfiguration.Api.saveDraftProjectAndContinueLater"
                      }, "UpdateNetworkProjectPrivateLineConfiguration::Error", [
                        "Error",
                        x._0
                      ]);
                  "Something went wrong trying to update the project".error(undefined, 1000, ReactNotifications.NotificationManager);
                }));
  }
  validate(form);
  if (isValid(results(form))) {
    return $$Promise.wait(Api.saveNetworkProjectDraftWithDocumentsAndContinueLater(form.input.documents, form.input.geographicalRegion), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Project.index);
                  }
                  SentryLogger.error1({
                        rootModule: "CreateNetworkProjectPrivateLineConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "saveDraftProjectAndContinueLater",
                        fullPath: "CreateNetworkProjectPrivateLineConfiguration.Api.saveDraftProjectAndContinueLater"
                      }, "saveDraftProjectAndContinueLater::saveProjectDraft::Error", [
                        "Error",
                        x._0
                      ]);
                  "Something went wrong trying to save the draft".error(undefined, 1000, ReactNotifications.NotificationManager);
                }));
  }
  
}

function cancelCreation() {
  $$Promise.wait(Api.cancelProjectCreation(), (function (x) {
          if (x.TAG === "Ok") {
            return Url.visit(Routes_Project.index);
          } else {
            return SentryLogger.error1({
                        rootModule: "CreateNetworkProjectPrivateLineConfiguration",
                        subModulePath: {
                          hd: "Api",
                          tl: /* [] */0
                        },
                        value: "cancelCreation",
                        fullPath: "CreateNetworkProjectPrivateLineConfiguration.Api.cancelCreation"
                      }, "CancelProjectCreation::Error", [
                        "Error",
                        x._0
                      ]);
          }
        }));
}

var Api$1 = {
  persistDraft: persistDraft,
  submitForm: submitForm,
  saveDraftProjectAndContinueLater: saveDraftProjectAndContinueLater,
  cancelCreation: cancelCreation
};

function isAlreadyDocumentsAvailable(alreadyUploadedDocuments) {
  return alreadyUploadedDocuments.length !== 0;
}

function isRegionFieldVisible(documents, alreadyUploadedDocuments, deletedDocumentIds) {
  var remainingUploadedDocuments = Belt_Array.keep(alreadyUploadedDocuments, (function ($$document) {
          if (alreadyUploadedDocuments.length !== 0) {
            return !Js_array.includes($$document.id, deletedDocumentIds);
          } else {
            return false;
          }
        }));
  if (documents.length !== 0) {
    return true;
  } else {
    return remainingUploadedDocuments.length !== 0;
  }
}

function CreateNetworkProjectPrivateLineConfiguration(props) {
  var alreadyUploadedDocuments = props.alreadyUploadedDocuments;
  var userLoginStatus = props.userLoginStatus;
  var mobile = props.mobile;
  var options = props.options;
  var context = props.context;
  var initialInput = React.useMemo((function () {
          var geographicalRegion;
          var data;
          if (context.TAG === "Draft") {
            var data$1 = context.data;
            var geographicalRegion$1 = context.geographicalRegion;
            if (data$1 === undefined) {
              return {
                      configurations: [CreateNetworkProjectPrivateLineConfigurationForm.Configuration.empty()],
                      documents: [],
                      deletedDocumentIds: [],
                      geographicalRegion: geographicalRegion$1,
                      validateRegion: true
                    };
            }
            geographicalRegion = geographicalRegion$1;
            data = data$1;
          } else {
            geographicalRegion = context.geographicalRegion;
            data = context.data;
          }
          return {
                  configurations: data,
                  documents: [],
                  deletedDocumentIds: [],
                  geographicalRegion: geographicalRegion,
                  validateRegion: alreadyUploadedDocuments.length === 0
                };
        }), []);
  var match = React.useState(function () {
        return "Hidden";
      });
  var toggleSignInModal = match[1];
  var signInModal = match[0];
  var form = CreateNetworkProjectPrivateLineConfigurationForm.useForm(initialInput, (function (output, form) {
          submitForm(output, context, form.notifyOnFailure);
        }));
  var initialState = React.useMemo((function () {
          var data;
          if (context.TAG === "Draft") {
            var data$1 = context.data;
            if (data$1 !== undefined) {
              data = data$1;
            } else {
              var shown = ["Shown"];
              return {
                      panelsVisibility: shown,
                      locationsVisibility: shown,
                      userLoginStatus: userLoginStatus
                    };
            }
          } else {
            data = context.data;
          }
          var shown$1 = Belt_Array.map(data, (function (param) {
                  return "Shown";
                }));
          return {
                  panelsVisibility: shown$1,
                  locationsVisibility: shown$1,
                  userLoginStatus: userLoginStatus
                };
        }), []);
  var match$1 = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "AddPanel") {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        panelsVisibility: Belt_Array.concat(state.panelsVisibility, ["Shown"]),
                        locationsVisibility: state.locationsVisibility,
                        userLoginStatus: state.userLoginStatus
                      },
                      _1: (function (param) {
                          form.addConfiguration(CreateNetworkProjectPrivateLineConfigurationForm.Configuration.empty());
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        panelsVisibility: Belt_Array.map(state.panelsVisibility, (function (param) {
                                return "Shown";
                              })),
                        locationsVisibility: state.locationsVisibility,
                        userLoginStatus: state.userLoginStatus
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "RemovePanel" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: remove(state.panelsVisibility, action.at),
                          locationsVisibility: state.locationsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "TogglePanelVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: toggle(state.panelsVisibility, action.at),
                          locationsVisibility: state.locationsVisibility,
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "ToggleConfigurationLocationsVisibility" :
                return {
                        TAG: "Update",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          locationsVisibility: toggle(state.locationsVisibility, action.at),
                          userLoginStatus: state.userLoginStatus
                        }
                      };
            case "AddDocument" :
                var v = Belt_Array.concat(form.input.documents, [action._0]);
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            form.updateDocuments((function (input, v) {
                                    return {
                                            configurations: input.configurations,
                                            documents: v,
                                            deletedDocumentIds: input.deletedDocumentIds,
                                            geographicalRegion: input.geographicalRegion,
                                            validateRegion: input.validateRegion
                                          };
                                  }), v);
                          })
                      };
            case "RemoveDocument" :
                var $$document = action._0;
                var v$1 = Js_array.filter((function (formDoc) {
                        return formDoc.name !== $$document.name;
                      }), form.input.documents);
                var isRegionEmpty = !isRegionFieldVisible(v$1, alreadyUploadedDocuments, form.input.deletedDocumentIds);
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            form.updateDocuments((function (input, v) {
                                    return {
                                            configurations: input.configurations,
                                            documents: v,
                                            deletedDocumentIds: input.deletedDocumentIds,
                                            geographicalRegion: input.geographicalRegion,
                                            validateRegion: input.validateRegion
                                          };
                                  }), v$1);
                            if (isRegionEmpty) {
                              return form.updateValidateRegion((function (input, param) {
                                            return {
                                                    configurations: input.configurations,
                                                    documents: input.documents,
                                                    deletedDocumentIds: input.deletedDocumentIds,
                                                    geographicalRegion: input.geographicalRegion,
                                                    validateRegion: true
                                                  };
                                          }), true);
                            }
                            
                          })
                      };
            case "RemoveAlreadUploadedDocument" :
                var v$2 = Belt_Array.concat(form.input.deletedDocumentIds, [action._0.id]);
                var isRegionEmpty$1 = !isRegionFieldVisible(form.input.documents, alreadyUploadedDocuments, v$2);
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            form.updateDeletedDocumentIds((function (input, v) {
                                    return {
                                            configurations: input.configurations,
                                            documents: input.documents,
                                            deletedDocumentIds: v,
                                            geographicalRegion: input.geographicalRegion,
                                            validateRegion: input.validateRegion
                                          };
                                  }), v$2);
                            if (isRegionEmpty$1) {
                              return form.updateValidateRegion((function (input, param) {
                                            return {
                                                    configurations: input.configurations,
                                                    documents: input.documents,
                                                    deletedDocumentIds: input.deletedDocumentIds,
                                                    geographicalRegion: input.geographicalRegion,
                                                    validateRegion: true
                                                  };
                                          }), true);
                            }
                            
                          })
                      };
            case "ToggleLoginStatus" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          panelsVisibility: state.panelsVisibility,
                          locationsVisibility: state.locationsVisibility,
                          userLoginStatus: action._0
                        },
                        _1: (function (param) {
                            saveDraftProjectAndContinueLater(form, context);
                          })
                      };
            
          }
        }));
  var dispatch = match$1[1];
  var state = match$1[0];
  var tmp;
  if (isRegionFieldVisible(form.input.documents, alreadyUploadedDocuments, form.input.deletedDocumentIds)) {
    var domId$1 = domId("geographical-region", 0);
    var result = form.geographicalRegionResult;
    var tmp$1;
    tmp$1 = result !== undefined && result.TAG !== "Ok" ? JsxRuntime.jsx("div", {
            children: result._0,
            className: css.errorMessage
          }) : null;
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx("h4", {
                  children: "Geographical region",
                  className: css.geographicalRegionsTitle
                }),
            JsxRuntime.jsx("p", {
                  children: "Please, select whether locations in the document are USA only or global? it will help us to match proper providers",
                  className: css.geographicalRegionsDescription
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsxs(Select.make, {
                          id: domId$1,
                          value: form.input.geographicalRegion,
                          status: Belt_Option.map(form.geographicalRegionResult, (function (x) {
                                  if (x.TAG === "Ok") {
                                    return "Valid";
                                  } else {
                                    return "Error";
                                  }
                                })),
                          className: css.input,
                          wrapperClassName: css.selectWrapper,
                          iconSize: "XXS",
                          iconColor: "GrayText",
                          onChange: (function ($$event) {
                              var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.geographicalRegions, (function (x) {
                                          return ReactForm.value($$event) === x;
                                        })), "");
                              form.updateGeographicalRegion((function (input, value) {
                                      return {
                                              configurations: input.configurations,
                                              documents: input.documents,
                                              deletedDocumentIds: input.deletedDocumentIds,
                                              geographicalRegion: value,
                                              validateRegion: input.validateRegion
                                            };
                                    }), value);
                            }),
                          onBlur: (function (param) {
                              form.blurGeographicalRegion();
                            }),
                          children: [
                            JsxRuntime.jsx(Select.DefaultOption.make, {
                                  children: "Select..."
                                }),
                            Belt_Array.map(options.geographicalRegions, (function (x) {
                                    return JsxRuntime.jsx(Select.$$Option.make, {
                                                value: x,
                                                children: x
                                              }, x);
                                  }))
                          ]
                        }),
                    tmp$1
                  ],
                  className: css.field
                })
          ],
          className: css.geographicalRegionsSection
        });
  } else {
    tmp = null;
  }
  var match$2 = state.userLoginStatus;
  var tmp$2;
  if (match$2 === "LoggedIn") {
    var tmp$3;
    tmp$3 = context.TAG === "Draft" ? "Next" : "Save and Next";
    var match$3 = form.status;
    var tmp$4;
    tmp$4 = typeof match$3 !== "object" || match$3.TAG !== "Submitting" ? "Enabled" : "Busy";
    tmp$2 = [
      tmp$3,
      (function () {
          var isRegionEmpty = !isRegionFieldVisible(form.input.documents, alreadyUploadedDocuments, form.input.deletedDocumentIds);
          if (isRegionEmpty) {
            form.updateGeographicalRegion((function (input, param) {
                    return {
                            configurations: input.configurations,
                            documents: input.documents,
                            deletedDocumentIds: input.deletedDocumentIds,
                            geographicalRegion: "",
                            validateRegion: input.validateRegion
                          };
                  }), "");
          }
          form.submit();
        }),
      tmp$4
    ];
  } else {
    tmp$2 = [
      "Next",
      (function () {
          toggleSignInModal(function (param) {
                return Visibility.toggle(signInModal);
              });
        }),
      "Enabled"
    ];
  }
  var match$4 = state.userLoginStatus;
  var tmp$5;
  tmp$5 = match$4 === "LoggedIn" && props.projectStatus === "Draft" ? [
      "Save and Continue Later",
      (function () {
          saveDraftProjectAndContinueLater(form, context);
        })
    ] : undefined;
  var tmp$6;
  tmp$6 = signInModal === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
          close: (function () {
              toggleSignInModal(function (param) {
                    return Visibility.toggle(signInModal);
                  });
            }),
          callback: (function () {
              dispatch({
                    TAG: "ToggleLoginStatus",
                    _0: "LoggedIn"
                  });
              Url.visit(Routes_Project.Network.PrivateLine.$$new);
            }),
          setUserData: props.setUserData,
          subTitle: "Sign In to save the project detail and continue later"
        }) : null;
  return JsxRuntime.jsxs(ProjectWizard.Container.make, {
              children: [
                JsxRuntime.jsxs(ProjectWizard.Content.make, {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("h1", {
                                    children: "Step 1: Configure Your Private Line Service",
                                    className: css.title
                                  }),
                              className: css.tooltipAndHeadingWrapper
                            }),
                        JsxRuntime.jsx(ProjectWizard.H2.make, {
                              children: "Add Your Locations, Private Line Services and Features"
                            }),
                        JsxRuntime.jsx("div", {
                              children: "Please add at least one Primary Location (A) and one additional Location (Z). If you have many locations, there is a \"Bulk Upload\" button at the bottom where you can upload multiple documents about your Private Line requirements at once.",
                              className: css.subHeading
                            }),
                        JsxRuntime.jsx("div", {
                              children: Belt_Array.mapWithIndex(form.input.configurations, (function (configurationIndex, configuration) {
                                      var visibility = state.panelsVisibility[configurationIndex];
                                      var match = form.input.configurations;
                                      var tmp;
                                      if (visibility === "Shown") {
                                        var domId$2 = domId("start-date", configurationIndex);
                                        var result = form.configurationStartDateResult(configurationIndex);
                                        var tmp$1;
                                        tmp$1 = result !== undefined && result.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$2;
                                        tmp$2 = result !== undefined && result.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$3 = domId("contract-length", configurationIndex);
                                        var result$1 = form.configurationContractLengthResult(configurationIndex);
                                        var tmp$3;
                                        tmp$3 = result$1 !== undefined && result$1.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$4;
                                        tmp$4 = result$1 !== undefined && result$1.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$1._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$4 = domId("street-address", configurationIndex);
                                        var result$2 = form.configurationStreetAddressResult(configurationIndex);
                                        var tmp$5;
                                        tmp$5 = result$2 !== undefined && result$2.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$6;
                                        tmp$6 = result$2 !== undefined && result$2.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$2._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$5 = domId("building-or-floor", configurationIndex);
                                        var result$3 = form.configurationBuildingOrFloorResult(configurationIndex);
                                        var tmp$7;
                                        tmp$7 = result$3 !== undefined && result$3.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$8;
                                        tmp$8 = result$3 !== undefined && result$3.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$3._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$6 = domId("suite", configurationIndex);
                                        var result$4 = form.configurationSuiteResult(configurationIndex);
                                        var tmp$9;
                                        tmp$9 = result$4 !== undefined && result$4.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$10;
                                        tmp$10 = result$4 !== undefined && result$4.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$4._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$7 = domId("city-or-town", configurationIndex);
                                        var result$5 = form.configurationCityOrTownResult(configurationIndex);
                                        var tmp$11;
                                        tmp$11 = result$5 !== undefined && result$5.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$12;
                                        tmp$12 = result$5 !== undefined && result$5.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$5._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$8 = domId("sub-locality", configurationIndex);
                                        var result$6 = form.configurationSubLocalityResult(configurationIndex);
                                        var tmp$13;
                                        tmp$13 = result$6 !== undefined && result$6.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$14;
                                        tmp$14 = result$6 !== undefined && result$6.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$6._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$9 = domId("state-or-province", configurationIndex);
                                        var result$7 = form.configurationStateOrProvinceResult(configurationIndex);
                                        var tmp$15;
                                        tmp$15 = result$7 !== undefined && result$7.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$16;
                                        tmp$16 = result$7 !== undefined && result$7.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$7._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$10 = domId("country", configurationIndex);
                                        var result$8 = form.configurationCountryResult(configurationIndex);
                                        var tmp$17;
                                        tmp$17 = result$8 !== undefined && result$8.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$18;
                                        tmp$18 = result$8 !== undefined && result$8.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$8._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$11 = domId("postal-code", configurationIndex);
                                        var result$9 = form.configurationPostalCodeResult(configurationIndex);
                                        var tmp$19;
                                        tmp$19 = result$9 !== undefined && result$9.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$20;
                                        tmp$20 = result$9 !== undefined && result$9.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$9._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$12 = domId("prefix", configurationIndex);
                                        var result$10 = form.configurationPrefixResult(configurationIndex);
                                        var tmp$21;
                                        tmp$21 = result$10 !== undefined && result$10.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$22;
                                        tmp$22 = result$10 !== undefined && result$10.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$10._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$13 = domId("access-type", configurationIndex);
                                        var result$11 = form.configurationAccessTypeResult(configurationIndex);
                                        var tmp$23;
                                        tmp$23 = result$11 !== undefined && result$11.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$24;
                                        tmp$24 = result$11 !== undefined && result$11.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$11._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$14 = domId("port-speed", configurationIndex);
                                        var result$12 = form.configurationPortSpeedResult(configurationIndex);
                                        var tmp$25;
                                        tmp$25 = result$12 !== undefined && result$12.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$26;
                                        tmp$26 = result$12 !== undefined && result$12.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$12._0,
                                                className: css.errorMessage
                                              }) : null;
                                        var domId$15 = domId("additional-location-details", configurationIndex);
                                        var result$13 = form.configurationAdditionalLocationDetailsResult(configurationIndex);
                                        var tmp$27;
                                        tmp$27 = result$13 !== undefined && result$13.TAG !== "Ok" ? css.errorLabel : "";
                                        var tmp$28;
                                        tmp$28 = result$13 !== undefined && result$13.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                children: result$13._0,
                                                className: css.errorMessage
                                              }) : null;
                                        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsx(ProjectWizardPanel.HeadlessSection.make, {
                                                      children: JsxRuntime.jsxs("div", {
                                                            children: [
                                                              JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      JsxRuntime.jsx(Label.make, {
                                                                            forId: domId$2,
                                                                            status: result,
                                                                            className: Cx.cx([
                                                                                  css.label,
                                                                                  css.requiredLabel,
                                                                                  tmp$1
                                                                                ]),
                                                                            children: "Requested Install Date:"
                                                                          }),
                                                                      JsxRuntime.jsx(DatePicker.make, {
                                                                            id: domId$2,
                                                                            value: Belt_Option.map(configuration.startDate, (function (prim) {
                                                                                    return prim;
                                                                                  })),
                                                                            fromMonth: Caml_option.some(AddDays(new Date(), 1)),
                                                                            disabledDays: [{
                                                                                from: new Date(0, 0),
                                                                                to: new Date()
                                                                              }],
                                                                            status: Belt_Option.map(result, (function (x) {
                                                                                    if (x.TAG === "Ok") {
                                                                                      return "Valid";
                                                                                    } else {
                                                                                      return "Error";
                                                                                    }
                                                                                  })),
                                                                            onDayChange: (function (date, param, param$1) {
                                                                                form.updateConfigurationStartDate(configurationIndex, (function (input, value) {
                                                                                        return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                      return {
                                                                                                              key: configuration.key,
                                                                                                              startDate: value,
                                                                                                              contractLength: configuration.contractLength,
                                                                                                              streetAddress: configuration.streetAddress,
                                                                                                              buildingOrFloor: configuration.buildingOrFloor,
                                                                                                              suite: configuration.suite,
                                                                                                              cityOrTown: configuration.cityOrTown,
                                                                                                              subLocality: configuration.subLocality,
                                                                                                              stateOrProvince: configuration.stateOrProvince,
                                                                                                              country: configuration.country,
                                                                                                              postalCode: configuration.postalCode,
                                                                                                              prefix: configuration.prefix,
                                                                                                              additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                              privateLineFeatures: configuration.privateLineFeatures,
                                                                                                              accessType: configuration.accessType,
                                                                                                              portSpeed: configuration.portSpeed,
                                                                                                              locations: configuration.locations
                                                                                                            };
                                                                                                    }));
                                                                                      }), Caml_option.some(date));
                                                                                persistDraft([
                                                                                      CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                              return {
                                                                                                      key: configuration.key,
                                                                                                      startDate: Caml_option.some(date),
                                                                                                      contractLength: configuration.contractLength,
                                                                                                      streetAddress: configuration.streetAddress,
                                                                                                      buildingOrFloor: configuration.buildingOrFloor,
                                                                                                      suite: configuration.suite,
                                                                                                      cityOrTown: configuration.cityOrTown,
                                                                                                      subLocality: configuration.subLocality,
                                                                                                      stateOrProvince: configuration.stateOrProvince,
                                                                                                      country: configuration.country,
                                                                                                      postalCode: configuration.postalCode,
                                                                                                      prefix: configuration.prefix,
                                                                                                      additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                      privateLineFeatures: configuration.privateLineFeatures,
                                                                                                      accessType: configuration.accessType,
                                                                                                      portSpeed: configuration.portSpeed,
                                                                                                      locations: configuration.locations
                                                                                                    };
                                                                                            })),
                                                                                      context
                                                                                    ]);
                                                                              }),
                                                                            onHide: (function () {
                                                                                form.blurConfigurationStartDate(configurationIndex);
                                                                              }),
                                                                            className: css.input
                                                                          }),
                                                                      tmp$2
                                                                    ],
                                                                    className: css.field
                                                                  }),
                                                              JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      JsxRuntime.jsx(Label.make, {
                                                                            forId: domId$3,
                                                                            status: result$1,
                                                                            className: Cx.cx([
                                                                                  css.label,
                                                                                  css.requiredLabel,
                                                                                  tmp$3
                                                                                ]),
                                                                            children: "Contract Length:"
                                                                          }),
                                                                      JsxRuntime.jsxs(Select.make, {
                                                                            id: domId$3,
                                                                            value: Belt_Option.mapWithDefault(configuration.contractLength, "", (function (cl) {
                                                                                    return String(cl.value);
                                                                                  })),
                                                                            status: Belt_Option.map(result$1, (function (x) {
                                                                                    if (x.TAG === "Ok") {
                                                                                      return "Valid";
                                                                                    } else {
                                                                                      return "Error";
                                                                                    }
                                                                                  })),
                                                                            className: css.input,
                                                                            wrapperClassName: css.selectWrapper,
                                                                            iconSize: "XXS",
                                                                            iconColor: "GrayText",
                                                                            onChange: (function ($$event) {
                                                                                var value = Belt_Array.getBy(options.contractLengths, (function (cl) {
                                                                                        return Belt_Option.mapWithDefault(Belt_Int.fromString(ReactForm.value($$event)), false, (function (value) {
                                                                                                      return value === cl.value;
                                                                                                    }));
                                                                                      }));
                                                                                form.updateConfigurationContractLength(configurationIndex, (function (input, value) {
                                                                                        return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                      return {
                                                                                                              key: configuration.key,
                                                                                                              startDate: configuration.startDate,
                                                                                                              contractLength: value,
                                                                                                              streetAddress: configuration.streetAddress,
                                                                                                              buildingOrFloor: configuration.buildingOrFloor,
                                                                                                              suite: configuration.suite,
                                                                                                              cityOrTown: configuration.cityOrTown,
                                                                                                              subLocality: configuration.subLocality,
                                                                                                              stateOrProvince: configuration.stateOrProvince,
                                                                                                              country: configuration.country,
                                                                                                              postalCode: configuration.postalCode,
                                                                                                              prefix: configuration.prefix,
                                                                                                              additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                              privateLineFeatures: configuration.privateLineFeatures,
                                                                                                              accessType: configuration.accessType,
                                                                                                              portSpeed: configuration.portSpeed,
                                                                                                              locations: configuration.locations
                                                                                                            };
                                                                                                    }));
                                                                                      }), value);
                                                                                persistDraft([
                                                                                      CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                              return {
                                                                                                      key: configuration.key,
                                                                                                      startDate: configuration.startDate,
                                                                                                      contractLength: value,
                                                                                                      streetAddress: configuration.streetAddress,
                                                                                                      buildingOrFloor: configuration.buildingOrFloor,
                                                                                                      suite: configuration.suite,
                                                                                                      cityOrTown: configuration.cityOrTown,
                                                                                                      subLocality: configuration.subLocality,
                                                                                                      stateOrProvince: configuration.stateOrProvince,
                                                                                                      country: configuration.country,
                                                                                                      postalCode: configuration.postalCode,
                                                                                                      prefix: configuration.prefix,
                                                                                                      additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                      privateLineFeatures: configuration.privateLineFeatures,
                                                                                                      accessType: configuration.accessType,
                                                                                                      portSpeed: configuration.portSpeed,
                                                                                                      locations: configuration.locations
                                                                                                    };
                                                                                            })),
                                                                                      context
                                                                                    ]);
                                                                              }),
                                                                            onBlur: (function (param) {
                                                                                form.blurConfigurationContractLength(configurationIndex);
                                                                              }),
                                                                            children: [
                                                                              JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                    children: "Select..."
                                                                                  }),
                                                                              Belt_Array.map(options.contractLengths, (function (cl) {
                                                                                      return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                  value: String(cl.value),
                                                                                                  children: cl.label
                                                                                                }, String(cl.value));
                                                                                    }))
                                                                            ]
                                                                          }),
                                                                      tmp$4
                                                                    ],
                                                                    className: css.field
                                                                  })
                                                            ],
                                                            className: Cx.cx([
                                                                  css.fieldRow,
                                                                  css.unfilled
                                                                ])
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(ProjectWizardPanel.Section.make, {
                                                      title: "Location (A)",
                                                      children: [
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$4,
                                                                              status: result$2,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$5
                                                                                  ]),
                                                                              children: "Street Address:"
                                                                            }),
                                                                        JsxRuntime.jsx(ReactGeoSuggestField.make, {
                                                                              id: domId$4,
                                                                              value: configuration.streetAddress,
                                                                              radius: "20",
                                                                              status: Belt_Option.map(result$2, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              location: new (google.maps.LatLng)(53.558572, 9.9278215),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationStreetAddress(configurationIndex);
                                                                                }),
                                                                              onChange: (function (value) {
                                                                                  form.updateConfigurationStreetAddress(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: value,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: value,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: configuration.cityOrTown,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: configuration.stateOrProvince,
                                                                                                        country: configuration.country,
                                                                                                        postalCode: configuration.postalCode,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onSuggestSelect: (function (suggest) {
                                                                                  var result = ReactGeoSuggest.ReactGeoSuggestData.fromJson(suggest);
                                                                                  var gmaps = result.gmaps;
                                                                                  if (gmaps === undefined) {
                                                                                    return ;
                                                                                  }
                                                                                  var addresses = gmaps.address_components;
                                                                                  var cityOrTown = {
                                                                                    contents: configuration.cityOrTown
                                                                                  };
                                                                                  var stateOrProvince = {
                                                                                    contents: configuration.stateOrProvince
                                                                                  };
                                                                                  var country = {
                                                                                    contents: configuration.country
                                                                                  };
                                                                                  var postalCode = {
                                                                                    contents: configuration.postalCode
                                                                                  };
                                                                                  Belt_Array.forEach(addresses, (function (address) {
                                                                                          var addressType = Belt_Array.get(address.types, 0);
                                                                                          if (addressType !== undefined) {
                                                                                            if (addressType === "locality" || addressType === "postal_town") {
                                                                                              cityOrTown.contents = address.long_name;
                                                                                            }
                                                                                            if (addressType === "administrative_area_level_1") {
                                                                                              stateOrProvince.contents = address.long_name;
                                                                                            }
                                                                                            if (addressType === "country") {
                                                                                              country.contents = address.long_name;
                                                                                            }
                                                                                            if (addressType === "postal_code") {
                                                                                              postalCode.contents = address.long_name;
                                                                                              return ;
                                                                                            } else {
                                                                                              return ;
                                                                                            }
                                                                                          }
                                                                                          
                                                                                        }));
                                                                                  form.updateConfigurationStreetAddress(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: value,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), gmaps.name);
                                                                                  form.updateConfigurationCityOrTown(configurationIndex, (function (input, cityOrTown) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), cityOrTown.contents);
                                                                                  form.updateConfigurationStateOrProvince(configurationIndex, (function (input, stateOrProvince) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), stateOrProvince.contents);
                                                                                  form.updateConfigurationCountry(configurationIndex, (function (input, country) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), country.contents);
                                                                                  form.updateConfigurationPostalCode(configurationIndex, (function (input, postalCode) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), postalCode.contents);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: gmaps.name,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: cityOrTown.contents,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: stateOrProvince.contents,
                                                                                                        country: country.contents,
                                                                                                        postalCode: postalCode.contents,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$6
                                                                      ],
                                                                      className: Cx.cx([
                                                                            css.field,
                                                                            css.elongated
                                                                          ])
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$5,
                                                                              status: result$3,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$7
                                                                                  ]),
                                                                              children: "Building/Floor:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$5,
                                                                              value: configuration.buildingOrFloor,
                                                                              status: Belt_Option.flatMap(result$3, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        if (x._0 !== undefined) {
                                                                                          return "Valid";
                                                                                        } else {
                                                                                          return ;
                                                                                        }
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationBuildingOrFloor(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationBuildingOrFloor(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: value,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: configuration.streetAddress,
                                                                                                        buildingOrFloor: value,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: configuration.cityOrTown,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: configuration.stateOrProvince,
                                                                                                        country: configuration.country,
                                                                                                        postalCode: configuration.postalCode,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$8
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$6,
                                                                              status: result$4,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$9
                                                                                  ]),
                                                                              children: "Suite:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$6,
                                                                              value: configuration.suite,
                                                                              status: Belt_Option.flatMap(result$4, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        if (x._0 !== undefined) {
                                                                                          return "Valid";
                                                                                        } else {
                                                                                          return ;
                                                                                        }
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationSuite(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationSuite(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: value,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: configuration.streetAddress,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: value,
                                                                                                        cityOrTown: configuration.cityOrTown,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: configuration.stateOrProvince,
                                                                                                        country: configuration.country,
                                                                                                        postalCode: configuration.postalCode,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$10
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.justified
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$7,
                                                                              status: result$5,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$11
                                                                                  ]),
                                                                              children: "City/Town:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$7,
                                                                              value: configuration.cityOrTown,
                                                                              status: Belt_Option.map(result$5, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationCityOrTown(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationCityOrTown(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: value,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: configuration.streetAddress,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: value,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: configuration.stateOrProvince,
                                                                                                        country: configuration.country,
                                                                                                        postalCode: configuration.postalCode,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$12
                                                                      ],
                                                                      className: Cx.cx([
                                                                            css.field,
                                                                            css.moderate
                                                                          ])
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$8,
                                                                              status: result$6,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$13
                                                                                  ]),
                                                                              children: "Sub-Locality:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$8,
                                                                              value: configuration.subLocality,
                                                                              status: Belt_Option.flatMap(result$6, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        if (x._0 !== undefined) {
                                                                                          return "Valid";
                                                                                        } else {
                                                                                          return ;
                                                                                        }
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationSubLocality(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationSubLocality(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: value,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: configuration.streetAddress,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: configuration.cityOrTown,
                                                                                                        subLocality: value,
                                                                                                        stateOrProvince: configuration.stateOrProvince,
                                                                                                        country: configuration.country,
                                                                                                        postalCode: configuration.postalCode,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$14
                                                                      ],
                                                                      className: Cx.cx([
                                                                            css.field,
                                                                            css.moderate
                                                                          ])
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$9,
                                                                              status: result$7,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$15
                                                                                  ]),
                                                                              children: "State/Province:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$9,
                                                                              value: configuration.stateOrProvince,
                                                                              status: Belt_Option.map(result$7, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationStateOrProvince(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationStateOrProvince(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: value,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: configuration.streetAddress,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: configuration.cityOrTown,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: value,
                                                                                                        country: configuration.country,
                                                                                                        postalCode: configuration.postalCode,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$16
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.justified
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$10,
                                                                              status: result$8,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$17
                                                                                  ]),
                                                                              children: "Country:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$10,
                                                                              value: configuration.country,
                                                                              status: Belt_Option.map(result$8, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationCountry(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationCountry(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: value,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: configuration.streetAddress,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: configuration.cityOrTown,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: configuration.stateOrProvince,
                                                                                                        country: value,
                                                                                                        postalCode: configuration.postalCode,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$18
                                                                      ],
                                                                      className: Cx.cx([
                                                                            css.field,
                                                                            css.moderate
                                                                          ])
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$11,
                                                                              status: result$9,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$19
                                                                                  ]),
                                                                              children: "Postal Code:"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$11,
                                                                              value: configuration.postalCode,
                                                                              status: Belt_Option.map(result$9, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationPostalCode(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationPostalCode(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: value,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: configuration.streetAddress,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: configuration.cityOrTown,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: configuration.stateOrProvince,
                                                                                                        country: configuration.country,
                                                                                                        postalCode: value,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$20
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$12,
                                                                              status: result$10,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    tmp$21
                                                                                  ]),
                                                                              children: "Prefix (NPA/NXX):"
                                                                            }),
                                                                        JsxRuntime.jsx(TextField.make, {
                                                                              id: domId$12,
                                                                              value: configuration.prefix,
                                                                              status: Belt_Option.flatMap(result$10, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        if (x._0 !== undefined) {
                                                                                          return "Valid";
                                                                                        } else {
                                                                                          return ;
                                                                                        }
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationPrefix(configurationIndex);
                                                                                }),
                                                                              onChange: (function ($$event) {
                                                                                  var value = ReactForm.value($$event);
                                                                                  form.updateConfigurationPrefix(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: value,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: configuration.streetAddress,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: configuration.cityOrTown,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: configuration.stateOrProvince,
                                                                                                        country: configuration.country,
                                                                                                        postalCode: configuration.postalCode,
                                                                                                        prefix: value,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                })
                                                                            }),
                                                                        tmp$22
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.unfilled
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsxs("div", {
                                                              children: [
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$13,
                                                                              status: result$11,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$23
                                                                                  ]),
                                                                              children: "Access Type"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$13,
                                                                              value: configuration.accessType,
                                                                              status: Belt_Option.map(result$11, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.accessTypes, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  form.updateConfigurationAccessType(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: value,
                                                                                                                portSpeed: configuration.portSpeed,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: configuration.streetAddress,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: configuration.cityOrTown,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: configuration.stateOrProvince,
                                                                                                        country: configuration.country,
                                                                                                        postalCode: configuration.postalCode,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: value,
                                                                                                        portSpeed: configuration.portSpeed,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationAccessType(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.accessTypes, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$24
                                                                      ],
                                                                      className: css.field
                                                                    }),
                                                                JsxRuntime.jsxs("div", {
                                                                      children: [
                                                                        JsxRuntime.jsx(Label.make, {
                                                                              forId: domId$14,
                                                                              status: result$12,
                                                                              className: Cx.cx([
                                                                                    css.label,
                                                                                    css.requiredLabel,
                                                                                    tmp$25
                                                                                  ]),
                                                                              children: "Port Speed"
                                                                            }),
                                                                        JsxRuntime.jsxs(Select.make, {
                                                                              id: domId$14,
                                                                              value: configuration.portSpeed,
                                                                              status: Belt_Option.map(result$12, (function (x) {
                                                                                      if (x.TAG === "Ok") {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return "Error";
                                                                                      }
                                                                                    })),
                                                                              className: css.input,
                                                                              wrapperClassName: css.selectWrapper,
                                                                              iconSize: "XXS",
                                                                              iconColor: "GrayText",
                                                                              onChange: (function ($$event) {
                                                                                  var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.portSpeeds, (function (x) {
                                                                                              return ReactForm.value($$event) === x;
                                                                                            })), "");
                                                                                  form.updateConfigurationPortSpeed(configurationIndex, (function (input, value) {
                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                        return {
                                                                                                                key: configuration.key,
                                                                                                                startDate: configuration.startDate,
                                                                                                                contractLength: configuration.contractLength,
                                                                                                                streetAddress: configuration.streetAddress,
                                                                                                                buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                suite: configuration.suite,
                                                                                                                cityOrTown: configuration.cityOrTown,
                                                                                                                subLocality: configuration.subLocality,
                                                                                                                stateOrProvince: configuration.stateOrProvince,
                                                                                                                country: configuration.country,
                                                                                                                postalCode: configuration.postalCode,
                                                                                                                prefix: configuration.prefix,
                                                                                                                additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                accessType: configuration.accessType,
                                                                                                                portSpeed: value,
                                                                                                                locations: configuration.locations
                                                                                                              };
                                                                                                      }));
                                                                                        }), value);
                                                                                  persistDraft([
                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                return {
                                                                                                        key: configuration.key,
                                                                                                        startDate: configuration.startDate,
                                                                                                        contractLength: configuration.contractLength,
                                                                                                        streetAddress: configuration.streetAddress,
                                                                                                        buildingOrFloor: configuration.buildingOrFloor,
                                                                                                        suite: configuration.suite,
                                                                                                        cityOrTown: configuration.cityOrTown,
                                                                                                        subLocality: configuration.subLocality,
                                                                                                        stateOrProvince: configuration.stateOrProvince,
                                                                                                        country: configuration.country,
                                                                                                        postalCode: configuration.postalCode,
                                                                                                        prefix: configuration.prefix,
                                                                                                        additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                        privateLineFeatures: configuration.privateLineFeatures,
                                                                                                        accessType: configuration.accessType,
                                                                                                        portSpeed: value,
                                                                                                        locations: configuration.locations
                                                                                                      };
                                                                                              })),
                                                                                        context
                                                                                      ]);
                                                                                }),
                                                                              onBlur: (function (param) {
                                                                                  form.blurConfigurationPortSpeed(configurationIndex);
                                                                                }),
                                                                              children: [
                                                                                JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                      children: "Select..."
                                                                                    }),
                                                                                Belt_Array.map(options.portSpeeds, (function (x) {
                                                                                        return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                    value: x,
                                                                                                    children: x
                                                                                                  }, x);
                                                                                      }))
                                                                              ]
                                                                            }),
                                                                        tmp$26
                                                                      ],
                                                                      className: css.field
                                                                    })
                                                              ],
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.unfilled
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              children: JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                                                    items: options.privateLineFeatures,
                                                                    mobile: mobile,
                                                                    children: (function (option) {
                                                                        var domId$16 = domId("private-line-features" + option, configurationIndex);
                                                                        return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                                    id: domId$16,
                                                                                    size: "MD",
                                                                                    checked: Belt_SetString.has(configuration.privateLineFeatures, option),
                                                                                    containerClassName: css.checkbox,
                                                                                    onChange: (function ($$event) {
                                                                                        var value = ReactForm.checked($$event) ? Belt_SetString.add(configuration.privateLineFeatures, option) : Belt_SetString.remove(configuration.privateLineFeatures, option);
                                                                                        form.updateConfigurationPrivateLineFeatures(configurationIndex, (function (input, value) {
                                                                                                return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                              return {
                                                                                                                      key: configuration.key,
                                                                                                                      startDate: configuration.startDate,
                                                                                                                      contractLength: configuration.contractLength,
                                                                                                                      streetAddress: configuration.streetAddress,
                                                                                                                      buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                      suite: configuration.suite,
                                                                                                                      cityOrTown: configuration.cityOrTown,
                                                                                                                      subLocality: configuration.subLocality,
                                                                                                                      stateOrProvince: configuration.stateOrProvince,
                                                                                                                      country: configuration.country,
                                                                                                                      postalCode: configuration.postalCode,
                                                                                                                      prefix: configuration.prefix,
                                                                                                                      additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                      privateLineFeatures: value,
                                                                                                                      accessType: configuration.accessType,
                                                                                                                      portSpeed: configuration.portSpeed,
                                                                                                                      locations: configuration.locations
                                                                                                                    };
                                                                                                            }));
                                                                                              }), value);
                                                                                        persistDraft([
                                                                                              CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                                      return {
                                                                                                              key: configuration.key,
                                                                                                              startDate: configuration.startDate,
                                                                                                              contractLength: configuration.contractLength,
                                                                                                              streetAddress: configuration.streetAddress,
                                                                                                              buildingOrFloor: configuration.buildingOrFloor,
                                                                                                              suite: configuration.suite,
                                                                                                              cityOrTown: configuration.cityOrTown,
                                                                                                              subLocality: configuration.subLocality,
                                                                                                              stateOrProvince: configuration.stateOrProvince,
                                                                                                              country: configuration.country,
                                                                                                              postalCode: configuration.postalCode,
                                                                                                              prefix: configuration.prefix,
                                                                                                              additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                              privateLineFeatures: value,
                                                                                                              accessType: configuration.accessType,
                                                                                                              portSpeed: configuration.portSpeed,
                                                                                                              locations: configuration.locations
                                                                                                            };
                                                                                                    })),
                                                                                              context
                                                                                            ]);
                                                                                      }),
                                                                                    onBlur: (function (param) {
                                                                                        form.blurConfigurationPrivateLineFeatures(configurationIndex);
                                                                                      }),
                                                                                    children: option
                                                                                  }, domId$16);
                                                                      })
                                                                  }),
                                                              className: Cx.cx([
                                                                    css.field,
                                                                    css.expanded
                                                                  ])
                                                            }),
                                                        JsxRuntime.jsx("div", {
                                                              children: JsxRuntime.jsxs("div", {
                                                                    children: [
                                                                      JsxRuntime.jsx(Label.make, {
                                                                            forId: domId$15,
                                                                            status: result$13,
                                                                            className: Cx.cx([
                                                                                  css.label,
                                                                                  tmp$27
                                                                                ]),
                                                                            children: "Additional Location Details:"
                                                                          }),
                                                                      JsxRuntime.jsx(Textarea.make, {
                                                                            id: domId$15,
                                                                            value: configuration.additionalLocationDetails,
                                                                            rows: 3,
                                                                            status: Belt_Option.flatMap(result$13, (function (x) {
                                                                                    if (x.TAG === "Ok") {
                                                                                      if (x._0 !== undefined) {
                                                                                        return "Valid";
                                                                                      } else {
                                                                                        return ;
                                                                                      }
                                                                                    } else {
                                                                                      return "Error";
                                                                                    }
                                                                                  })),
                                                                            className: css.input,
                                                                            onBlur: (function (param) {
                                                                                form.blurConfigurationAdditionalLocationDetails(configurationIndex);
                                                                              }),
                                                                            onChange: (function ($$event) {
                                                                                var value = ReactForm.value($$event);
                                                                                form.updateConfigurationAdditionalLocationDetails(configurationIndex, (function (input, value) {
                                                                                        return CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(input, configurationIndex, (function (configuration) {
                                                                                                      return {
                                                                                                              key: configuration.key,
                                                                                                              startDate: configuration.startDate,
                                                                                                              contractLength: configuration.contractLength,
                                                                                                              streetAddress: configuration.streetAddress,
                                                                                                              buildingOrFloor: configuration.buildingOrFloor,
                                                                                                              suite: configuration.suite,
                                                                                                              cityOrTown: configuration.cityOrTown,
                                                                                                              subLocality: configuration.subLocality,
                                                                                                              stateOrProvince: configuration.stateOrProvince,
                                                                                                              country: configuration.country,
                                                                                                              postalCode: configuration.postalCode,
                                                                                                              prefix: configuration.prefix,
                                                                                                              additionalLocationDetails: value,
                                                                                                              privateLineFeatures: configuration.privateLineFeatures,
                                                                                                              accessType: configuration.accessType,
                                                                                                              portSpeed: configuration.portSpeed,
                                                                                                              locations: configuration.locations
                                                                                                            };
                                                                                                    }));
                                                                                      }), value);
                                                                                persistDraft([
                                                                                      CreateNetworkProjectPrivateLineConfigurationForm.Configuration.update(form.input, configurationIndex, (function (configuration) {
                                                                                              return {
                                                                                                      key: configuration.key,
                                                                                                      startDate: configuration.startDate,
                                                                                                      contractLength: configuration.contractLength,
                                                                                                      streetAddress: configuration.streetAddress,
                                                                                                      buildingOrFloor: configuration.buildingOrFloor,
                                                                                                      suite: configuration.suite,
                                                                                                      cityOrTown: configuration.cityOrTown,
                                                                                                      subLocality: configuration.subLocality,
                                                                                                      stateOrProvince: configuration.stateOrProvince,
                                                                                                      country: configuration.country,
                                                                                                      postalCode: configuration.postalCode,
                                                                                                      prefix: configuration.prefix,
                                                                                                      additionalLocationDetails: value,
                                                                                                      privateLineFeatures: configuration.privateLineFeatures,
                                                                                                      accessType: configuration.accessType,
                                                                                                      portSpeed: configuration.portSpeed,
                                                                                                      locations: configuration.locations
                                                                                                    };
                                                                                            })),
                                                                                      context
                                                                                    ]);
                                                                              })
                                                                          }),
                                                                      tmp$28
                                                                    ],
                                                                    className: Cx.cx([
                                                                          css.field,
                                                                          css.expanded
                                                                        ])
                                                                  }),
                                                              className: Cx.cx([
                                                                    css.fieldRow,
                                                                    css.justified
                                                                  ])
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        Belt_Array.mapWithIndex(configuration.locations, (function (locationIndex, $$location) {
                                                                var match = configuration.locations;
                                                                var domId = locationDomId("street-address", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result = form.configurationLocationStreetAddressResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp;
                                                                tmp = result !== undefined && result.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$1;
                                                                tmp$1 = result !== undefined && result.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$1 = locationDomId("building-or-floor", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$1 = form.configurationLocationBuildingOrFloorResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$2;
                                                                tmp$2 = result$1 !== undefined && result$1.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$3;
                                                                tmp$3 = result$1 !== undefined && result$1.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$1._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$2 = locationDomId("suite", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$2 = form.configurationLocationSuiteResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$4;
                                                                tmp$4 = result$2 !== undefined && result$2.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$5;
                                                                tmp$5 = result$2 !== undefined && result$2.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$2._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$3 = locationDomId("city-or-town", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$3 = form.configurationLocationCityOrTownResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$6;
                                                                tmp$6 = result$3 !== undefined && result$3.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$7;
                                                                tmp$7 = result$3 !== undefined && result$3.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$3._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$4 = locationDomId("sub-locality", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$4 = form.configurationLocationSubLocalityResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$8;
                                                                tmp$8 = result$4 !== undefined && result$4.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$9;
                                                                tmp$9 = result$4 !== undefined && result$4.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$4._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$5 = locationDomId("state-or-province", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$5 = form.configurationLocationStateOrProvinceResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$10;
                                                                tmp$10 = result$5 !== undefined && result$5.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$11;
                                                                tmp$11 = result$5 !== undefined && result$5.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$5._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$6 = locationDomId("country", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$6 = form.configurationLocationCountryResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$12;
                                                                tmp$12 = result$6 !== undefined && result$6.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$13;
                                                                tmp$13 = result$6 !== undefined && result$6.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$6._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$7 = locationDomId("postal-code", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$7 = form.configurationLocationPostalCodeResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$14;
                                                                tmp$14 = result$7 !== undefined && result$7.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$15;
                                                                tmp$15 = result$7 !== undefined && result$7.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$7._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$8 = locationDomId("prefix", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$8 = form.configurationLocationPrefixResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$16;
                                                                tmp$16 = result$8 !== undefined && result$8.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$17;
                                                                tmp$17 = result$8 !== undefined && result$8.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$8._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$9 = locationDomId("access-type", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$9 = form.configurationLocationAccessTypeResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$18;
                                                                tmp$18 = result$9 !== undefined && result$9.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$19;
                                                                tmp$19 = result$9 !== undefined && result$9.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$9._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$10 = locationDomId("port-speed", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$10 = form.configurationLocationPortSpeedResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$20;
                                                                tmp$20 = result$10 !== undefined && result$10.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$21;
                                                                tmp$21 = result$10 !== undefined && result$10.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$10._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                var domId$11 = locationDomId("additional-location-details", [
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var result$11 = form.configurationLocationAdditionalLocationDetailsResult([
                                                                      configurationIndex,
                                                                      locationIndex
                                                                    ]);
                                                                var tmp$22;
                                                                tmp$22 = result$11 !== undefined && result$11.TAG !== "Ok" ? css.errorLabel : "";
                                                                var tmp$23;
                                                                tmp$23 = result$11 !== undefined && result$11.TAG !== "Ok" ? JsxRuntime.jsx("div", {
                                                                        children: result$11._0,
                                                                        className: css.errorMessage
                                                                      }) : null;
                                                                return JsxRuntime.jsx("div", {
                                                                            children: JsxRuntime.jsx(ProjectWizardPanel.Section.make, {
                                                                                  title: locationTitle(locationIndex),
                                                                                  children: JsxRuntime.jsxs(ProjectWizardPanel.HeadlessSectionWithDeleteControl.make, {
                                                                                        deleteControl: match.length !== 1 ? (function () {
                                                                                              form.removeConfigurationLocation([
                                                                                                    configurationIndex,
                                                                                                    locationIndex
                                                                                                  ]);
                                                                                              persistDraft([
                                                                                                    CreateNetworkProjectPrivateLineConfigurationForm.$$Location.remove(form.input, [
                                                                                                          configurationIndex,
                                                                                                          locationIndex
                                                                                                        ]),
                                                                                                    context
                                                                                                  ]);
                                                                                            }) : undefined,
                                                                                        children: [
                                                                                          JsxRuntime.jsxs("div", {
                                                                                                children: [
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId,
                                                                                                                status: result,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      css.requiredLabel,
                                                                                                                      tmp
                                                                                                                    ]),
                                                                                                                children: "Street Address:"
                                                                                                              }),
                                                                                                          JsxRuntime.jsx(ReactGeoSuggestField.make, {
                                                                                                                id: domId,
                                                                                                                value: $$location.streetAddress,
                                                                                                                radius: "20",
                                                                                                                status: Belt_Option.map(result, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          return "Valid";
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                location: new (google.maps.LatLng)(53.558572, 9.9278215),
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationStreetAddress([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onChange: (function (value) {
                                                                                                                    form.updateConfigurationLocationStreetAddress([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function ($$location) {
                                                                                                                                          return {
                                                                                                                                                  key: $$location.key,
                                                                                                                                                  streetAddress: value,
                                                                                                                                                  buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                                  suite: $$location.suite,
                                                                                                                                                  cityOrTown: $$location.cityOrTown,
                                                                                                                                                  subLocality: $$location.subLocality,
                                                                                                                                                  stateOrProvince: $$location.stateOrProvince,
                                                                                                                                                  country: $$location.country,
                                                                                                                                                  postalCode: $$location.postalCode,
                                                                                                                                                  prefix: $$location.prefix,
                                                                                                                                                  additionalLocationDetails: $$location.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: $$location.privateLineFeatures,
                                                                                                                                                  accessType: $$location.accessType,
                                                                                                                                                  portSpeed: $$location.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function ($$location) {
                                                                                                                                  return {
                                                                                                                                          key: $$location.key,
                                                                                                                                          streetAddress: value,
                                                                                                                                          buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                          suite: $$location.suite,
                                                                                                                                          cityOrTown: $$location.cityOrTown,
                                                                                                                                          subLocality: $$location.subLocality,
                                                                                                                                          stateOrProvince: $$location.stateOrProvince,
                                                                                                                                          country: $$location.country,
                                                                                                                                          postalCode: $$location.postalCode,
                                                                                                                                          prefix: $$location.prefix,
                                                                                                                                          additionalLocationDetails: $$location.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: $$location.privateLineFeatures,
                                                                                                                                          accessType: $$location.accessType,
                                                                                                                                          portSpeed: $$location.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onSuggestSelect: (function (suggest) {
                                                                                                                    var result = ReactGeoSuggest.ReactGeoSuggestData.fromJson(suggest);
                                                                                                                    var gmaps = result.gmaps;
                                                                                                                    if (gmaps === undefined) {
                                                                                                                      return ;
                                                                                                                    }
                                                                                                                    var addresses = gmaps.address_components;
                                                                                                                    var cityOrTown = {
                                                                                                                      contents: $$location.cityOrTown
                                                                                                                    };
                                                                                                                    var stateOrProvince = {
                                                                                                                      contents: $$location.stateOrProvince
                                                                                                                    };
                                                                                                                    var country = {
                                                                                                                      contents: $$location.country
                                                                                                                    };
                                                                                                                    var postalCode = {
                                                                                                                      contents: $$location.postalCode
                                                                                                                    };
                                                                                                                    Belt_Array.forEach(addresses, (function (address) {
                                                                                                                            var addressType = Belt_Array.get(address.types, 0);
                                                                                                                            if (addressType !== undefined) {
                                                                                                                              if (addressType === "locality" || addressType === "postal_town") {
                                                                                                                                cityOrTown.contents = address.long_name;
                                                                                                                              }
                                                                                                                              if (addressType === "administrative_area_level_1") {
                                                                                                                                stateOrProvince.contents = address.long_name;
                                                                                                                              }
                                                                                                                              if (addressType === "country") {
                                                                                                                                country.contents = address.long_name;
                                                                                                                              }
                                                                                                                              if (addressType === "postal_code") {
                                                                                                                                postalCode.contents = address.long_name;
                                                                                                                                return ;
                                                                                                                              } else {
                                                                                                                                return ;
                                                                                                                              }
                                                                                                                            }
                                                                                                                            
                                                                                                                          }));
                                                                                                                    form.updateConfigurationLocationStreetAddress([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function ($$location) {
                                                                                                                                          return {
                                                                                                                                                  key: $$location.key,
                                                                                                                                                  streetAddress: value,
                                                                                                                                                  buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                                  suite: $$location.suite,
                                                                                                                                                  cityOrTown: $$location.cityOrTown,
                                                                                                                                                  subLocality: $$location.subLocality,
                                                                                                                                                  stateOrProvince: $$location.stateOrProvince,
                                                                                                                                                  country: $$location.country,
                                                                                                                                                  postalCode: $$location.postalCode,
                                                                                                                                                  prefix: $$location.prefix,
                                                                                                                                                  additionalLocationDetails: $$location.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: $$location.privateLineFeatures,
                                                                                                                                                  accessType: $$location.accessType,
                                                                                                                                                  portSpeed: $$location.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), gmaps.name);
                                                                                                                    form.updateConfigurationLocationCityOrTown([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, cityOrTown) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function ($$location) {
                                                                                                                                          return {
                                                                                                                                                  key: $$location.key,
                                                                                                                                                  streetAddress: $$location.streetAddress,
                                                                                                                                                  buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                                  suite: $$location.suite,
                                                                                                                                                  cityOrTown: cityOrTown,
                                                                                                                                                  subLocality: $$location.subLocality,
                                                                                                                                                  stateOrProvince: $$location.stateOrProvince,
                                                                                                                                                  country: $$location.country,
                                                                                                                                                  postalCode: $$location.postalCode,
                                                                                                                                                  prefix: $$location.prefix,
                                                                                                                                                  additionalLocationDetails: $$location.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: $$location.privateLineFeatures,
                                                                                                                                                  accessType: $$location.accessType,
                                                                                                                                                  portSpeed: $$location.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), cityOrTown.contents);
                                                                                                                    form.updateConfigurationLocationStateOrProvince([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, stateOrProvince) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function ($$location) {
                                                                                                                                          return {
                                                                                                                                                  key: $$location.key,
                                                                                                                                                  streetAddress: $$location.streetAddress,
                                                                                                                                                  buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                                  suite: $$location.suite,
                                                                                                                                                  cityOrTown: $$location.cityOrTown,
                                                                                                                                                  subLocality: $$location.subLocality,
                                                                                                                                                  stateOrProvince: stateOrProvince,
                                                                                                                                                  country: $$location.country,
                                                                                                                                                  postalCode: $$location.postalCode,
                                                                                                                                                  prefix: $$location.prefix,
                                                                                                                                                  additionalLocationDetails: $$location.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: $$location.privateLineFeatures,
                                                                                                                                                  accessType: $$location.accessType,
                                                                                                                                                  portSpeed: $$location.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), stateOrProvince.contents);
                                                                                                                    form.updateConfigurationLocationCountry([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, country) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function ($$location) {
                                                                                                                                          return {
                                                                                                                                                  key: $$location.key,
                                                                                                                                                  streetAddress: $$location.streetAddress,
                                                                                                                                                  buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                                  suite: $$location.suite,
                                                                                                                                                  cityOrTown: $$location.cityOrTown,
                                                                                                                                                  subLocality: $$location.subLocality,
                                                                                                                                                  stateOrProvince: $$location.stateOrProvince,
                                                                                                                                                  country: country,
                                                                                                                                                  postalCode: $$location.postalCode,
                                                                                                                                                  prefix: $$location.prefix,
                                                                                                                                                  additionalLocationDetails: $$location.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: $$location.privateLineFeatures,
                                                                                                                                                  accessType: $$location.accessType,
                                                                                                                                                  portSpeed: $$location.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), country.contents);
                                                                                                                    form.updateConfigurationLocationPostalCode([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, postalCode) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function ($$location) {
                                                                                                                                          return {
                                                                                                                                                  key: $$location.key,
                                                                                                                                                  streetAddress: $$location.streetAddress,
                                                                                                                                                  buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                                  suite: $$location.suite,
                                                                                                                                                  cityOrTown: $$location.cityOrTown,
                                                                                                                                                  subLocality: $$location.subLocality,
                                                                                                                                                  stateOrProvince: $$location.stateOrProvince,
                                                                                                                                                  country: $$location.country,
                                                                                                                                                  postalCode: postalCode,
                                                                                                                                                  prefix: $$location.prefix,
                                                                                                                                                  additionalLocationDetails: $$location.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: $$location.privateLineFeatures,
                                                                                                                                                  accessType: $$location.accessType,
                                                                                                                                                  portSpeed: $$location.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), postalCode.contents);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function ($$location) {
                                                                                                                                  return {
                                                                                                                                          key: $$location.key,
                                                                                                                                          streetAddress: gmaps.name,
                                                                                                                                          buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                          suite: $$location.suite,
                                                                                                                                          cityOrTown: cityOrTown.contents,
                                                                                                                                          subLocality: $$location.subLocality,
                                                                                                                                          stateOrProvince: stateOrProvince.contents,
                                                                                                                                          country: country.contents,
                                                                                                                                          postalCode: postalCode.contents,
                                                                                                                                          prefix: $$location.prefix,
                                                                                                                                          additionalLocationDetails: $$location.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: $$location.privateLineFeatures,
                                                                                                                                          accessType: $$location.accessType,
                                                                                                                                          portSpeed: $$location.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  })
                                                                                                              }),
                                                                                                          tmp$1
                                                                                                        ],
                                                                                                        className: Cx.cx([
                                                                                                              css.field,
                                                                                                              css.elongatedLocation
                                                                                                            ])
                                                                                                      }),
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId$1,
                                                                                                                status: result$1,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      tmp$2
                                                                                                                    ]),
                                                                                                                children: "Building/Floor:"
                                                                                                              }),
                                                                                                          JsxRuntime.jsx(TextField.make, {
                                                                                                                id: domId$1,
                                                                                                                value: $$location.buildingOrFloor,
                                                                                                                status: Belt_Option.flatMap(result$1, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          if (x._0 !== undefined) {
                                                                                                                            return "Valid";
                                                                                                                          } else {
                                                                                                                            return ;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationBuildingOrFloor([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onChange: (function ($$event) {
                                                                                                                    var value = ReactForm.value($$event);
                                                                                                                    form.updateConfigurationLocationBuildingOrFloor([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function (configuration) {
                                                                                                                                          return {
                                                                                                                                                  key: configuration.key,
                                                                                                                                                  streetAddress: configuration.streetAddress,
                                                                                                                                                  buildingOrFloor: value,
                                                                                                                                                  suite: configuration.suite,
                                                                                                                                                  cityOrTown: configuration.cityOrTown,
                                                                                                                                                  subLocality: configuration.subLocality,
                                                                                                                                                  stateOrProvince: configuration.stateOrProvince,
                                                                                                                                                  country: configuration.country,
                                                                                                                                                  postalCode: configuration.postalCode,
                                                                                                                                                  prefix: configuration.prefix,
                                                                                                                                                  additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                                  accessType: configuration.accessType,
                                                                                                                                                  portSpeed: configuration.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (configuration) {
                                                                                                                                  return {
                                                                                                                                          key: configuration.key,
                                                                                                                                          streetAddress: configuration.streetAddress,
                                                                                                                                          buildingOrFloor: value,
                                                                                                                                          suite: configuration.suite,
                                                                                                                                          cityOrTown: configuration.cityOrTown,
                                                                                                                                          subLocality: configuration.subLocality,
                                                                                                                                          stateOrProvince: configuration.stateOrProvince,
                                                                                                                                          country: configuration.country,
                                                                                                                                          postalCode: configuration.postalCode,
                                                                                                                                          prefix: configuration.prefix,
                                                                                                                                          additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                          accessType: configuration.accessType,
                                                                                                                                          portSpeed: configuration.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  })
                                                                                                              }),
                                                                                                          tmp$3
                                                                                                        ],
                                                                                                        className: css.field
                                                                                                      }),
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId$2,
                                                                                                                status: result$2,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      tmp$4
                                                                                                                    ]),
                                                                                                                children: "Suite:"
                                                                                                              }),
                                                                                                          JsxRuntime.jsx(TextField.make, {
                                                                                                                id: domId$2,
                                                                                                                value: $$location.suite,
                                                                                                                status: Belt_Option.flatMap(result$2, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          if (x._0 !== undefined) {
                                                                                                                            return "Valid";
                                                                                                                          } else {
                                                                                                                            return ;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationSuite([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onChange: (function ($$event) {
                                                                                                                    var value = ReactForm.value($$event);
                                                                                                                    form.updateConfigurationLocationSuite([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function (configuration) {
                                                                                                                                          return {
                                                                                                                                                  key: configuration.key,
                                                                                                                                                  streetAddress: configuration.streetAddress,
                                                                                                                                                  buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                                  suite: value,
                                                                                                                                                  cityOrTown: configuration.cityOrTown,
                                                                                                                                                  subLocality: configuration.subLocality,
                                                                                                                                                  stateOrProvince: configuration.stateOrProvince,
                                                                                                                                                  country: configuration.country,
                                                                                                                                                  postalCode: configuration.postalCode,
                                                                                                                                                  prefix: configuration.prefix,
                                                                                                                                                  additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                                  accessType: configuration.accessType,
                                                                                                                                                  portSpeed: configuration.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (configuration) {
                                                                                                                                  return {
                                                                                                                                          key: configuration.key,
                                                                                                                                          streetAddress: configuration.streetAddress,
                                                                                                                                          buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                          suite: value,
                                                                                                                                          cityOrTown: configuration.cityOrTown,
                                                                                                                                          subLocality: configuration.subLocality,
                                                                                                                                          stateOrProvince: configuration.stateOrProvince,
                                                                                                                                          country: configuration.country,
                                                                                                                                          postalCode: configuration.postalCode,
                                                                                                                                          prefix: configuration.prefix,
                                                                                                                                          additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                          accessType: configuration.accessType,
                                                                                                                                          portSpeed: configuration.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  })
                                                                                                              }),
                                                                                                          tmp$5
                                                                                                        ],
                                                                                                        className: css.field
                                                                                                      })
                                                                                                ],
                                                                                                className: Cx.cx([
                                                                                                      css.fieldRow,
                                                                                                      css.justified
                                                                                                    ])
                                                                                              }),
                                                                                          JsxRuntime.jsxs("div", {
                                                                                                children: [
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId$3,
                                                                                                                status: result$3,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      css.requiredLabel,
                                                                                                                      tmp$6
                                                                                                                    ]),
                                                                                                                children: "City/Town:"
                                                                                                              }),
                                                                                                          JsxRuntime.jsx(TextField.make, {
                                                                                                                id: domId$3,
                                                                                                                value: $$location.cityOrTown,
                                                                                                                status: Belt_Option.map(result$3, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          return "Valid";
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationCityOrTown([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onChange: (function ($$event) {
                                                                                                                    var value = ReactForm.value($$event);
                                                                                                                    form.updateConfigurationLocationCityOrTown([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function (configuration) {
                                                                                                                                          return {
                                                                                                                                                  key: configuration.key,
                                                                                                                                                  streetAddress: configuration.streetAddress,
                                                                                                                                                  buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                                  suite: configuration.suite,
                                                                                                                                                  cityOrTown: value,
                                                                                                                                                  subLocality: configuration.subLocality,
                                                                                                                                                  stateOrProvince: configuration.stateOrProvince,
                                                                                                                                                  country: configuration.country,
                                                                                                                                                  postalCode: configuration.postalCode,
                                                                                                                                                  prefix: configuration.prefix,
                                                                                                                                                  additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                                  accessType: configuration.accessType,
                                                                                                                                                  portSpeed: configuration.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (configuration) {
                                                                                                                                  return {
                                                                                                                                          key: configuration.key,
                                                                                                                                          streetAddress: configuration.streetAddress,
                                                                                                                                          buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                          suite: configuration.suite,
                                                                                                                                          cityOrTown: value,
                                                                                                                                          subLocality: configuration.subLocality,
                                                                                                                                          stateOrProvince: configuration.stateOrProvince,
                                                                                                                                          country: configuration.country,
                                                                                                                                          postalCode: configuration.postalCode,
                                                                                                                                          prefix: configuration.prefix,
                                                                                                                                          additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                          accessType: configuration.accessType,
                                                                                                                                          portSpeed: configuration.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  })
                                                                                                              }),
                                                                                                          tmp$7
                                                                                                        ],
                                                                                                        className: Cx.cx([
                                                                                                              css.field,
                                                                                                              css.moderateLocation
                                                                                                            ])
                                                                                                      }),
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId$4,
                                                                                                                status: result$4,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      tmp$8
                                                                                                                    ]),
                                                                                                                children: "Sub-Locality:"
                                                                                                              }),
                                                                                                          JsxRuntime.jsx(TextField.make, {
                                                                                                                id: domId$4,
                                                                                                                value: $$location.subLocality,
                                                                                                                status: Belt_Option.flatMap(result$4, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          if (x._0 !== undefined) {
                                                                                                                            return "Valid";
                                                                                                                          } else {
                                                                                                                            return ;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationSubLocality([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onChange: (function ($$event) {
                                                                                                                    var value = ReactForm.value($$event);
                                                                                                                    form.updateConfigurationLocationSubLocality([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function (configuration) {
                                                                                                                                          return {
                                                                                                                                                  key: configuration.key,
                                                                                                                                                  streetAddress: configuration.streetAddress,
                                                                                                                                                  buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                                  suite: configuration.suite,
                                                                                                                                                  cityOrTown: configuration.cityOrTown,
                                                                                                                                                  subLocality: value,
                                                                                                                                                  stateOrProvince: configuration.stateOrProvince,
                                                                                                                                                  country: configuration.country,
                                                                                                                                                  postalCode: configuration.postalCode,
                                                                                                                                                  prefix: configuration.prefix,
                                                                                                                                                  additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                                  accessType: configuration.accessType,
                                                                                                                                                  portSpeed: configuration.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (configuration) {
                                                                                                                                  return {
                                                                                                                                          key: configuration.key,
                                                                                                                                          streetAddress: configuration.streetAddress,
                                                                                                                                          buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                          suite: configuration.suite,
                                                                                                                                          cityOrTown: configuration.cityOrTown,
                                                                                                                                          subLocality: value,
                                                                                                                                          stateOrProvince: configuration.stateOrProvince,
                                                                                                                                          country: configuration.country,
                                                                                                                                          postalCode: configuration.postalCode,
                                                                                                                                          prefix: configuration.prefix,
                                                                                                                                          additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                          accessType: configuration.accessType,
                                                                                                                                          portSpeed: configuration.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  })
                                                                                                              }),
                                                                                                          tmp$9
                                                                                                        ],
                                                                                                        className: Cx.cx([
                                                                                                              css.field,
                                                                                                              css.moderateLocation
                                                                                                            ])
                                                                                                      }),
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId$5,
                                                                                                                status: result$5,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      css.requiredLabel,
                                                                                                                      tmp$10
                                                                                                                    ]),
                                                                                                                children: "State/Province:"
                                                                                                              }),
                                                                                                          JsxRuntime.jsx(TextField.make, {
                                                                                                                id: domId$5,
                                                                                                                value: $$location.stateOrProvince,
                                                                                                                status: Belt_Option.map(result$5, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          return "Valid";
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationStateOrProvince([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onChange: (function ($$event) {
                                                                                                                    var value = ReactForm.value($$event);
                                                                                                                    form.updateConfigurationLocationStateOrProvince([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function (configuration) {
                                                                                                                                          return {
                                                                                                                                                  key: configuration.key,
                                                                                                                                                  streetAddress: configuration.streetAddress,
                                                                                                                                                  buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                                  suite: configuration.suite,
                                                                                                                                                  cityOrTown: configuration.cityOrTown,
                                                                                                                                                  subLocality: configuration.subLocality,
                                                                                                                                                  stateOrProvince: value,
                                                                                                                                                  country: configuration.country,
                                                                                                                                                  postalCode: configuration.postalCode,
                                                                                                                                                  prefix: configuration.prefix,
                                                                                                                                                  additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                                  accessType: configuration.accessType,
                                                                                                                                                  portSpeed: configuration.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (configuration) {
                                                                                                                                  return {
                                                                                                                                          key: configuration.key,
                                                                                                                                          streetAddress: configuration.streetAddress,
                                                                                                                                          buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                          suite: configuration.suite,
                                                                                                                                          cityOrTown: configuration.cityOrTown,
                                                                                                                                          subLocality: configuration.subLocality,
                                                                                                                                          stateOrProvince: value,
                                                                                                                                          country: configuration.country,
                                                                                                                                          postalCode: configuration.postalCode,
                                                                                                                                          prefix: configuration.prefix,
                                                                                                                                          additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                          accessType: configuration.accessType,
                                                                                                                                          portSpeed: configuration.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  })
                                                                                                              }),
                                                                                                          tmp$11
                                                                                                        ],
                                                                                                        className: css.field
                                                                                                      })
                                                                                                ],
                                                                                                className: Cx.cx([
                                                                                                      css.fieldRow,
                                                                                                      css.justified
                                                                                                    ])
                                                                                              }),
                                                                                          JsxRuntime.jsxs("div", {
                                                                                                children: [
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId$6,
                                                                                                                status: result$6,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      css.requiredLabel,
                                                                                                                      tmp$12
                                                                                                                    ]),
                                                                                                                children: "Country:"
                                                                                                              }),
                                                                                                          JsxRuntime.jsx(TextField.make, {
                                                                                                                id: domId$6,
                                                                                                                value: $$location.country,
                                                                                                                status: Belt_Option.map(result$6, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          return "Valid";
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationCountry([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onChange: (function ($$event) {
                                                                                                                    var value = ReactForm.value($$event);
                                                                                                                    form.updateConfigurationLocationCountry([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function (configuration) {
                                                                                                                                          return {
                                                                                                                                                  key: configuration.key,
                                                                                                                                                  streetAddress: configuration.streetAddress,
                                                                                                                                                  buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                                  suite: configuration.suite,
                                                                                                                                                  cityOrTown: configuration.cityOrTown,
                                                                                                                                                  subLocality: configuration.subLocality,
                                                                                                                                                  stateOrProvince: configuration.stateOrProvince,
                                                                                                                                                  country: value,
                                                                                                                                                  postalCode: configuration.postalCode,
                                                                                                                                                  prefix: configuration.prefix,
                                                                                                                                                  additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                                  accessType: configuration.accessType,
                                                                                                                                                  portSpeed: configuration.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (configuration) {
                                                                                                                                  return {
                                                                                                                                          key: configuration.key,
                                                                                                                                          streetAddress: configuration.streetAddress,
                                                                                                                                          buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                          suite: configuration.suite,
                                                                                                                                          cityOrTown: configuration.cityOrTown,
                                                                                                                                          subLocality: configuration.subLocality,
                                                                                                                                          stateOrProvince: configuration.stateOrProvince,
                                                                                                                                          country: value,
                                                                                                                                          postalCode: configuration.postalCode,
                                                                                                                                          prefix: configuration.prefix,
                                                                                                                                          additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                          accessType: configuration.accessType,
                                                                                                                                          portSpeed: configuration.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  })
                                                                                                              }),
                                                                                                          tmp$13
                                                                                                        ],
                                                                                                        className: Cx.cx([
                                                                                                              css.field,
                                                                                                              css.moderateLocation
                                                                                                            ])
                                                                                                      }),
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId$7,
                                                                                                                status: result$7,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      css.requiredLabel,
                                                                                                                      tmp$14
                                                                                                                    ]),
                                                                                                                children: "Postal Code:"
                                                                                                              }),
                                                                                                          JsxRuntime.jsx(TextField.make, {
                                                                                                                id: domId$7,
                                                                                                                value: $$location.postalCode,
                                                                                                                status: Belt_Option.map(result$7, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          return "Valid";
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationPostalCode([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onChange: (function ($$event) {
                                                                                                                    var value = ReactForm.value($$event);
                                                                                                                    form.updateConfigurationLocationPostalCode([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function (configuration) {
                                                                                                                                          return {
                                                                                                                                                  key: configuration.key,
                                                                                                                                                  streetAddress: configuration.streetAddress,
                                                                                                                                                  buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                                  suite: configuration.suite,
                                                                                                                                                  cityOrTown: configuration.cityOrTown,
                                                                                                                                                  subLocality: configuration.subLocality,
                                                                                                                                                  stateOrProvince: configuration.stateOrProvince,
                                                                                                                                                  country: configuration.country,
                                                                                                                                                  postalCode: value,
                                                                                                                                                  prefix: configuration.prefix,
                                                                                                                                                  additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                                  accessType: configuration.accessType,
                                                                                                                                                  portSpeed: configuration.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (configuration) {
                                                                                                                                  return {
                                                                                                                                          key: configuration.key,
                                                                                                                                          streetAddress: configuration.streetAddress,
                                                                                                                                          buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                          suite: configuration.suite,
                                                                                                                                          cityOrTown: configuration.cityOrTown,
                                                                                                                                          subLocality: configuration.subLocality,
                                                                                                                                          stateOrProvince: configuration.stateOrProvince,
                                                                                                                                          country: configuration.country,
                                                                                                                                          postalCode: value,
                                                                                                                                          prefix: configuration.prefix,
                                                                                                                                          additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                          accessType: configuration.accessType,
                                                                                                                                          portSpeed: configuration.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  })
                                                                                                              }),
                                                                                                          tmp$15
                                                                                                        ],
                                                                                                        className: css.field
                                                                                                      }),
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId$8,
                                                                                                                status: result$8,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      tmp$16
                                                                                                                    ]),
                                                                                                                children: "Prefix (NPA/NXX):"
                                                                                                              }),
                                                                                                          JsxRuntime.jsx(TextField.make, {
                                                                                                                id: domId$8,
                                                                                                                value: $$location.prefix,
                                                                                                                status: Belt_Option.flatMap(result$8, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          if (x._0 !== undefined) {
                                                                                                                            return "Valid";
                                                                                                                          } else {
                                                                                                                            return ;
                                                                                                                          }
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationPrefix([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onChange: (function ($$event) {
                                                                                                                    var value = ReactForm.value($$event);
                                                                                                                    form.updateConfigurationLocationPrefix([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function (configuration) {
                                                                                                                                          return {
                                                                                                                                                  key: configuration.key,
                                                                                                                                                  streetAddress: configuration.streetAddress,
                                                                                                                                                  buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                                  suite: configuration.suite,
                                                                                                                                                  cityOrTown: configuration.cityOrTown,
                                                                                                                                                  subLocality: configuration.subLocality,
                                                                                                                                                  stateOrProvince: configuration.stateOrProvince,
                                                                                                                                                  country: configuration.country,
                                                                                                                                                  postalCode: configuration.postalCode,
                                                                                                                                                  prefix: value,
                                                                                                                                                  additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                                  accessType: configuration.accessType,
                                                                                                                                                  portSpeed: configuration.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (configuration) {
                                                                                                                                  return {
                                                                                                                                          key: configuration.key,
                                                                                                                                          streetAddress: configuration.streetAddress,
                                                                                                                                          buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                          suite: configuration.suite,
                                                                                                                                          cityOrTown: configuration.cityOrTown,
                                                                                                                                          subLocality: configuration.subLocality,
                                                                                                                                          stateOrProvince: configuration.stateOrProvince,
                                                                                                                                          country: configuration.country,
                                                                                                                                          postalCode: configuration.postalCode,
                                                                                                                                          prefix: value,
                                                                                                                                          additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                          accessType: configuration.accessType,
                                                                                                                                          portSpeed: configuration.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  })
                                                                                                              }),
                                                                                                          tmp$17
                                                                                                        ],
                                                                                                        className: css.field
                                                                                                      })
                                                                                                ],
                                                                                                className: Cx.cx([
                                                                                                      css.fieldRow,
                                                                                                      css.unfilled
                                                                                                    ])
                                                                                              }),
                                                                                          JsxRuntime.jsxs("div", {
                                                                                                children: [
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId$9,
                                                                                                                status: result$9,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      css.requiredLabel,
                                                                                                                      tmp$18
                                                                                                                    ]),
                                                                                                                children: "Access Type"
                                                                                                              }),
                                                                                                          JsxRuntime.jsxs(Select.make, {
                                                                                                                id: domId$9,
                                                                                                                value: $$location.accessType,
                                                                                                                status: Belt_Option.map(result$9, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          return "Valid";
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                wrapperClassName: css.selectWrapper,
                                                                                                                iconSize: "XXS",
                                                                                                                iconColor: "GrayText",
                                                                                                                onChange: (function ($$event) {
                                                                                                                    var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.accessTypes, (function (x) {
                                                                                                                                return ReactForm.value($$event) === x;
                                                                                                                              })), "");
                                                                                                                    form.updateConfigurationLocationAccessType([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function (configuration) {
                                                                                                                                          return {
                                                                                                                                                  key: configuration.key,
                                                                                                                                                  streetAddress: configuration.streetAddress,
                                                                                                                                                  buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                                  suite: configuration.suite,
                                                                                                                                                  cityOrTown: configuration.cityOrTown,
                                                                                                                                                  subLocality: configuration.subLocality,
                                                                                                                                                  stateOrProvince: configuration.stateOrProvince,
                                                                                                                                                  country: configuration.country,
                                                                                                                                                  postalCode: configuration.postalCode,
                                                                                                                                                  prefix: configuration.prefix,
                                                                                                                                                  additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                                  accessType: value,
                                                                                                                                                  portSpeed: configuration.portSpeed
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (configuration) {
                                                                                                                                  return {
                                                                                                                                          key: configuration.key,
                                                                                                                                          streetAddress: configuration.streetAddress,
                                                                                                                                          buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                          suite: configuration.suite,
                                                                                                                                          cityOrTown: configuration.cityOrTown,
                                                                                                                                          subLocality: configuration.subLocality,
                                                                                                                                          stateOrProvince: configuration.stateOrProvince,
                                                                                                                                          country: configuration.country,
                                                                                                                                          postalCode: configuration.postalCode,
                                                                                                                                          prefix: configuration.prefix,
                                                                                                                                          additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                          accessType: value,
                                                                                                                                          portSpeed: configuration.portSpeed
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationAccessType([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                children: [
                                                                                                                  JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                                                        children: "Select..."
                                                                                                                      }),
                                                                                                                  Belt_Array.map(options.accessTypes, (function (x) {
                                                                                                                          return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                                                      value: x,
                                                                                                                                      children: x
                                                                                                                                    }, x);
                                                                                                                        }))
                                                                                                                ]
                                                                                                              }),
                                                                                                          tmp$19
                                                                                                        ],
                                                                                                        className: css.field
                                                                                                      }),
                                                                                                  JsxRuntime.jsxs("div", {
                                                                                                        children: [
                                                                                                          JsxRuntime.jsx(Label.make, {
                                                                                                                forId: domId$10,
                                                                                                                status: result$10,
                                                                                                                className: Cx.cx([
                                                                                                                      css.label,
                                                                                                                      css.requiredLabel,
                                                                                                                      tmp$20
                                                                                                                    ]),
                                                                                                                children: "Port Speed"
                                                                                                              }),
                                                                                                          JsxRuntime.jsxs(Select.make, {
                                                                                                                id: domId$10,
                                                                                                                value: $$location.portSpeed,
                                                                                                                status: Belt_Option.map(result$10, (function (x) {
                                                                                                                        if (x.TAG === "Ok") {
                                                                                                                          return "Valid";
                                                                                                                        } else {
                                                                                                                          return "Error";
                                                                                                                        }
                                                                                                                      })),
                                                                                                                className: css.input,
                                                                                                                wrapperClassName: css.selectWrapper,
                                                                                                                iconSize: "XXS",
                                                                                                                iconColor: "GrayText",
                                                                                                                onChange: (function ($$event) {
                                                                                                                    var value = Belt_Option.getWithDefault(Belt_Array.getBy(options.portSpeeds, (function (x) {
                                                                                                                                return ReactForm.value($$event) === x;
                                                                                                                              })), "");
                                                                                                                    form.updateConfigurationLocationPortSpeed([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ], (function (input, value) {
                                                                                                                            return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                        configurationIndex,
                                                                                                                                        locationIndex
                                                                                                                                      ], (function (configuration) {
                                                                                                                                          return {
                                                                                                                                                  key: configuration.key,
                                                                                                                                                  streetAddress: configuration.streetAddress,
                                                                                                                                                  buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                                  suite: configuration.suite,
                                                                                                                                                  cityOrTown: configuration.cityOrTown,
                                                                                                                                                  subLocality: configuration.subLocality,
                                                                                                                                                  stateOrProvince: configuration.stateOrProvince,
                                                                                                                                                  country: configuration.country,
                                                                                                                                                  postalCode: configuration.postalCode,
                                                                                                                                                  prefix: configuration.prefix,
                                                                                                                                                  additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                                  privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                                  accessType: configuration.accessType,
                                                                                                                                                  portSpeed: value
                                                                                                                                                };
                                                                                                                                        }));
                                                                                                                          }), value);
                                                                                                                    persistDraft([
                                                                                                                          CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (configuration) {
                                                                                                                                  return {
                                                                                                                                          key: configuration.key,
                                                                                                                                          streetAddress: configuration.streetAddress,
                                                                                                                                          buildingOrFloor: configuration.buildingOrFloor,
                                                                                                                                          suite: configuration.suite,
                                                                                                                                          cityOrTown: configuration.cityOrTown,
                                                                                                                                          subLocality: configuration.subLocality,
                                                                                                                                          stateOrProvince: configuration.stateOrProvince,
                                                                                                                                          country: configuration.country,
                                                                                                                                          postalCode: configuration.postalCode,
                                                                                                                                          prefix: configuration.prefix,
                                                                                                                                          additionalLocationDetails: configuration.additionalLocationDetails,
                                                                                                                                          privateLineFeatures: configuration.privateLineFeatures,
                                                                                                                                          accessType: configuration.accessType,
                                                                                                                                          portSpeed: value
                                                                                                                                        };
                                                                                                                                })),
                                                                                                                          context
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                onBlur: (function (param) {
                                                                                                                    form.blurConfigurationLocationPortSpeed([
                                                                                                                          configurationIndex,
                                                                                                                          locationIndex
                                                                                                                        ]);
                                                                                                                  }),
                                                                                                                children: [
                                                                                                                  JsxRuntime.jsx(Select.DefaultOption.make, {
                                                                                                                        children: "Select..."
                                                                                                                      }),
                                                                                                                  Belt_Array.map(options.portSpeeds, (function (x) {
                                                                                                                          return JsxRuntime.jsx(Select.$$Option.make, {
                                                                                                                                      value: x,
                                                                                                                                      children: x
                                                                                                                                    }, x);
                                                                                                                        }))
                                                                                                                ]
                                                                                                              }),
                                                                                                          tmp$21
                                                                                                        ],
                                                                                                        className: css.field
                                                                                                      })
                                                                                                ],
                                                                                                className: Cx.cx([
                                                                                                      css.fieldRow,
                                                                                                      css.unfilled
                                                                                                    ])
                                                                                              }),
                                                                                          JsxRuntime.jsx("div", {
                                                                                                children: JsxRuntime.jsx(ProjectWizardPanel.ZebraGrid.make, {
                                                                                                      items: options.privateLineFeatures,
                                                                                                      mobile: mobile,
                                                                                                      children: (function (option) {
                                                                                                          var domId = locationDomId("private-line-features" + option, [
                                                                                                                configurationIndex,
                                                                                                                locationIndex
                                                                                                              ]);
                                                                                                          return JsxRuntime.jsx(Checkbox.WithLabel.make, {
                                                                                                                      id: domId,
                                                                                                                      size: "MD",
                                                                                                                      checked: Belt_SetString.has($$location.privateLineFeatures, option),
                                                                                                                      containerClassName: css.checkbox,
                                                                                                                      onChange: (function ($$event) {
                                                                                                                          var value = ReactForm.checked($$event) ? Belt_SetString.add($$location.privateLineFeatures, option) : Belt_SetString.remove($$location.privateLineFeatures, option);
                                                                                                                          form.updateConfigurationLocationPrivateLineFeatures([
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ], (function (input, value) {
                                                                                                                                  return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                              configurationIndex,
                                                                                                                                              locationIndex
                                                                                                                                            ], (function ($$location) {
                                                                                                                                                return {
                                                                                                                                                        key: $$location.key,
                                                                                                                                                        streetAddress: $$location.streetAddress,
                                                                                                                                                        buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                                        suite: $$location.suite,
                                                                                                                                                        cityOrTown: $$location.cityOrTown,
                                                                                                                                                        subLocality: $$location.subLocality,
                                                                                                                                                        stateOrProvince: $$location.stateOrProvince,
                                                                                                                                                        country: $$location.country,
                                                                                                                                                        postalCode: $$location.postalCode,
                                                                                                                                                        prefix: $$location.prefix,
                                                                                                                                                        additionalLocationDetails: $$location.additionalLocationDetails,
                                                                                                                                                        privateLineFeatures: value,
                                                                                                                                                        accessType: $$location.accessType,
                                                                                                                                                        portSpeed: $$location.portSpeed
                                                                                                                                                      };
                                                                                                                                              }));
                                                                                                                                }), value);
                                                                                                                          persistDraft([
                                                                                                                                CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                                      configurationIndex,
                                                                                                                                      locationIndex
                                                                                                                                    ], (function ($$location) {
                                                                                                                                        return {
                                                                                                                                                key: $$location.key,
                                                                                                                                                streetAddress: $$location.streetAddress,
                                                                                                                                                buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                                suite: $$location.suite,
                                                                                                                                                cityOrTown: $$location.cityOrTown,
                                                                                                                                                subLocality: $$location.subLocality,
                                                                                                                                                stateOrProvince: $$location.stateOrProvince,
                                                                                                                                                country: $$location.country,
                                                                                                                                                postalCode: $$location.postalCode,
                                                                                                                                                prefix: $$location.prefix,
                                                                                                                                                additionalLocationDetails: $$location.additionalLocationDetails,
                                                                                                                                                privateLineFeatures: value,
                                                                                                                                                accessType: $$location.accessType,
                                                                                                                                                portSpeed: $$location.portSpeed
                                                                                                                                              };
                                                                                                                                      })),
                                                                                                                                context
                                                                                                                              ]);
                                                                                                                        }),
                                                                                                                      onBlur: (function (param) {
                                                                                                                          form.blurConfigurationLocationPrivateLineFeatures([
                                                                                                                                configurationIndex,
                                                                                                                                locationIndex
                                                                                                                              ]);
                                                                                                                        }),
                                                                                                                      children: option
                                                                                                                    }, domId);
                                                                                                        })
                                                                                                    }),
                                                                                                className: Cx.cx([
                                                                                                      css.field,
                                                                                                      css.expanded
                                                                                                    ])
                                                                                              }),
                                                                                          JsxRuntime.jsx("div", {
                                                                                                children: JsxRuntime.jsxs("div", {
                                                                                                      children: [
                                                                                                        JsxRuntime.jsx(Label.make, {
                                                                                                              forId: domId$11,
                                                                                                              status: result$11,
                                                                                                              className: Cx.cx([
                                                                                                                    css.label,
                                                                                                                    tmp$22
                                                                                                                  ]),
                                                                                                              children: "Additional Location and Service Details:"
                                                                                                            }),
                                                                                                        JsxRuntime.jsx(Textarea.make, {
                                                                                                              id: domId$11,
                                                                                                              value: $$location.additionalLocationDetails,
                                                                                                              rows: 3,
                                                                                                              status: Belt_Option.flatMap(result$11, (function (x) {
                                                                                                                      if (x.TAG === "Ok") {
                                                                                                                        if (x._0 !== undefined) {
                                                                                                                          return "Valid";
                                                                                                                        } else {
                                                                                                                          return ;
                                                                                                                        }
                                                                                                                      } else {
                                                                                                                        return "Error";
                                                                                                                      }
                                                                                                                    })),
                                                                                                              className: css.input,
                                                                                                              onBlur: (function (param) {
                                                                                                                  form.blurConfigurationLocationAdditionalLocationDetails([
                                                                                                                        configurationIndex,
                                                                                                                        locationIndex
                                                                                                                      ]);
                                                                                                                }),
                                                                                                              onChange: (function ($$event) {
                                                                                                                  var value = ReactForm.value($$event);
                                                                                                                  form.updateConfigurationLocationAdditionalLocationDetails([
                                                                                                                        configurationIndex,
                                                                                                                        locationIndex
                                                                                                                      ], (function (input, value) {
                                                                                                                          return CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(input, [
                                                                                                                                      configurationIndex,
                                                                                                                                      locationIndex
                                                                                                                                    ], (function ($$location) {
                                                                                                                                        return {
                                                                                                                                                key: $$location.key,
                                                                                                                                                streetAddress: $$location.streetAddress,
                                                                                                                                                buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                                suite: $$location.suite,
                                                                                                                                                cityOrTown: $$location.cityOrTown,
                                                                                                                                                subLocality: $$location.subLocality,
                                                                                                                                                stateOrProvince: $$location.stateOrProvince,
                                                                                                                                                country: $$location.country,
                                                                                                                                                postalCode: $$location.postalCode,
                                                                                                                                                prefix: $$location.prefix,
                                                                                                                                                additionalLocationDetails: value,
                                                                                                                                                privateLineFeatures: $$location.privateLineFeatures,
                                                                                                                                                accessType: $$location.accessType,
                                                                                                                                                portSpeed: $$location.portSpeed
                                                                                                                                              };
                                                                                                                                      }));
                                                                                                                        }), value);
                                                                                                                  persistDraft([
                                                                                                                        CreateNetworkProjectPrivateLineConfigurationForm.$$Location.update(form.input, [
                                                                                                                              configurationIndex,
                                                                                                                              locationIndex
                                                                                                                            ], (function ($$location) {
                                                                                                                                return {
                                                                                                                                        key: $$location.key,
                                                                                                                                        streetAddress: $$location.streetAddress,
                                                                                                                                        buildingOrFloor: $$location.buildingOrFloor,
                                                                                                                                        suite: $$location.suite,
                                                                                                                                        cityOrTown: $$location.cityOrTown,
                                                                                                                                        subLocality: $$location.subLocality,
                                                                                                                                        stateOrProvince: $$location.stateOrProvince,
                                                                                                                                        country: $$location.country,
                                                                                                                                        postalCode: $$location.postalCode,
                                                                                                                                        prefix: $$location.prefix,
                                                                                                                                        additionalLocationDetails: value,
                                                                                                                                        privateLineFeatures: $$location.privateLineFeatures,
                                                                                                                                        accessType: $$location.accessType,
                                                                                                                                        portSpeed: $$location.portSpeed
                                                                                                                                      };
                                                                                                                              })),
                                                                                                                        context
                                                                                                                      ]);
                                                                                                                })
                                                                                                            }),
                                                                                                        tmp$23
                                                                                                      ],
                                                                                                      className: Cx.cx([
                                                                                                            css.field,
                                                                                                            css.expanded
                                                                                                          ])
                                                                                                    }),
                                                                                                className: Cx.cx([
                                                                                                      css.fieldRow,
                                                                                                      css.justified
                                                                                                    ])
                                                                                              })
                                                                                        ]
                                                                                      }, $$location.key)
                                                                                }),
                                                                            className: css.locationContainer
                                                                          }, $$location.key);
                                                              })),
                                                        JsxRuntime.jsx(Button.make, {
                                                              size: "SM",
                                                              color: "Gray",
                                                              buttonClassName: css.locationButton,
                                                              onClick: (function (param) {
                                                                  form.addConfigurationLocation(CreateNetworkProjectPrivateLineConfigurationForm.$$Location.empty(), configurationIndex);
                                                                }),
                                                              children: "+ Add Another Location (Z)"
                                                            })
                                                      ]
                                                    })
                                              ]
                                            });
                                      } else {
                                        tmp = null;
                                      }
                                      return JsxRuntime.jsx(ProjectWizardPanel.make, {
                                                  title: configurationTitle(configurationIndex),
                                                  visibility: visibility,
                                                  toggleVisibility: (function () {
                                                      dispatch({
                                                            TAG: "TogglePanelVisibility",
                                                            at: configurationIndex
                                                          });
                                                    }),
                                                  deleteControl: match.length !== 1 ? (function () {
                                                        form.removeConfiguration(configurationIndex);
                                                        dispatch({
                                                              TAG: "RemovePanel",
                                                              at: configurationIndex
                                                            });
                                                        persistDraft([
                                                              CreateNetworkProjectPrivateLineConfigurationForm.Configuration.remove(form.input, configurationIndex),
                                                              context
                                                            ]);
                                                      }) : undefined,
                                                  panelFooter: true,
                                                  children: tmp
                                                }, configuration.key);
                                    }))
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              onClick: (function (param) {
                                  dispatch("AddPanel");
                                }),
                              children: "+ Add One More Network Service"
                            })
                      ]
                    }),
                JsxRuntime.jsxs(ProjectWizard.Content.make, {
                      children: [
                        JsxRuntime.jsx(CreateNetworkProjectPrivateLineConfiguration_DocumentsWithRfpDetails.make, {
                              alreadyUploadedDocuments: Belt_Array.keep(alreadyUploadedDocuments, (function ($$document) {
                                      return !Js_array.includes($$document.id, form.input.deletedDocumentIds);
                                    })),
                              documents: form.input.documents,
                              removeAlreadyUploadedDocument: (function ($$document) {
                                  dispatch({
                                        TAG: "RemoveAlreadUploadedDocument",
                                        _0: $$document
                                      });
                                }),
                              removeDocument: (function ($$document) {
                                  dispatch({
                                        TAG: "RemoveDocument",
                                        _0: $$document
                                      });
                                })
                            }),
                        tmp,
                        JsxRuntime.jsx(Uploader.make, {
                              allowed: [
                                "Pdf",
                                "Doc",
                                "Docx",
                                "Csv",
                                "Vsd",
                                "Xls",
                                "Xlsx"
                              ],
                              multiple: true,
                              className: css.uploader,
                              upload: (function (files) {
                                  Belt_Array.map(files, (function (file) {
                                          var match = Uploader.FileType.fromMime(file.type);
                                          switch (match) {
                                            case "Jpg" :
                                            case "Png" :
                                            case "Avi" :
                                            case "Mp4" :
                                            case "Mov" :
                                            case "Wmv" :
                                                return ;
                                            default:
                                              return dispatch({
                                                          TAG: "AddDocument",
                                                          _0: file
                                                        });
                                          }
                                        }));
                                }),
                              setUploadHandler: (function (param) {
                                  
                                }),
                              children: JsxRuntime.jsxs(Button.make, {
                                    size: "SM",
                                    color: "Teal",
                                    buttonClassName: css.uploadDocumentButton,
                                    children: [
                                      JsxRuntime.jsx(IconUpload.make, {
                                            size: "LG",
                                            color: "White",
                                            className: css.uploadDocumentIcon
                                          }),
                                      "Bulk Upload"
                                    ]
                                  })
                            })
                      ]
                    }),
                JsxRuntime.jsx(ProjectWizardNavigation.make, {
                      stepLabel: "Step 1 of 3",
                      controls: {
                        TAG: "PreviousAndNextAndContinueLater",
                        next: tmp$2,
                        previous: [
                          "Cancel",
                          (function () {
                              cancelCreation();
                            }),
                          "Enabled"
                        ],
                        continueLater: tmp$5
                      },
                      mobile: mobile
                    }),
                JsxRuntime.jsx(ReactNotifications.NotificationContainer, {}),
                tmp$6
              ]
            });
}

var Configuration;

var Options;

var $$Location;

var make = CreateNetworkProjectPrivateLineConfiguration;

export {
  Configuration ,
  Options ,
  $$Location ,
  css ,
  domId ,
  locationDomId ,
  configurationTitle ,
  locationTitle ,
  Visibilities ,
  ValidateSaveAndContinueLaterForm ,
  Api$1 as Api,
  isAlreadyDocumentsAvailable ,
  isRegionFieldVisible ,
  make ,
}
/* css Not a pure module */
